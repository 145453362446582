import React, { FC, useEffect } from 'react'
import _ from 'lodash'
import * as Yup from 'yup'
import { dispatch } from 'store/store'
import { useSelector } from 'hooks/useSelector'
import { Components } from 'api/kyc/generated/client'
import BasicFormWrapper from 'modules/kyc-front/ui-elements/BasicForm'
import KycPersonalDetailsForm from './KycPersonalDetailsForm'
import KycContactForm from './KycContactForm'
import KycIdentificationForm from './KycIdentificationForm'
import KycSourceOfWealthForm from './KycSourceOfWealthForm'
import KycDeclarationsForm from './KycDeclarationsForm'
import KycCompanyDetailsForm from './KycCompanyDetailsForm'
import KycCompanyRegistrationAddress from './KycCompanyRegistrationAddress'
import KycCompanyOperationsForm from './KycCompanyOperationsForm'
import KycCompanyDocumentsForm from './KycCompanyDocumentsForm'
import KycCorrectnessDeclarationForm from './KycCorrectnessDeclarationForm'
import KycCompanyFinancialsForm from './KycCompanyFinancialsForm'
import KycPersonsListingForm from './KycPersonsListingForm'
import KycCompanySignatoriesForm from './KycCompanySignatoriesForm'
import { useLocation } from 'react-router-dom'
import { kycApplicationApprovalsGet } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsGet'

export type addressType = Components.Schemas.AddressEntry | undefined;
export type documentEntry = Components.Schemas.IdentityDocumentEntry | undefined;

interface KProps {
    fields: string[];
    applicationId: string;
    formGet: any;
    formUpdate?: any;
    formUpdateClear?: any;
    printRender?: boolean;
    validationAction?: any;
    isSection?: boolean;
    errorsListing?: boolean;
}

type ApiResponse = {
    [key: string]: any;
  };
  
type ValidationSchema = {
    [key: string]: Yup.StringSchema | Yup.ObjectSchema<any>;
};

type Person = {
    [key: string]: any;
};

interface FormMapping {
    component: FC<any>;
    props: Record<string, any>;
    steps?: any;
    section?: string;
}


const KycFormInner: FC<KProps> = ({
    fields,
    printRender,
    applicationId,
    formGet,
    formUpdate,
    formUpdateClear,
    validationAction,
    isSection,
    errorsListing
}) => {
    const envs = useSelector((state) => state.general.envs);
    const nav = useSelector((state) => state.general.nav);
    const kycApplication = useSelector((state) => state.user2.kycApplication);
    const { stepsScheme } = kycApplication;
    const person = kycApplication.kycApplication.values;
    const initialValues: Person = {};
    const validationSchema: { [key: string]: Yup.StringSchema } = {};
    const findPersonById = (id: string): any => {
        //@ts-ignore
        const { authorizedPersons, beneficialOwners } = person;
        //@ts-ignore
        return authorizedPersons?.find(el => el.id === id) || beneficialOwners?.find(el => el.id === id);
    };
    let el = useLocation();
    let apUboID = el.pathname.split('/')[envs.admin ? 4 : 3];
    fields?.forEach((key) => {
        let personEl = apUboID ? findPersonById(apUboID) : person;
        const value = _.get(personEl, key)
        const isUSPEP = key == 'isPoliticallyExposedPerson' || key === 'isUSNationality' || key === 'headOfficeAddress.sameAsRegistered'
        let initialValue: any;
        if (value === "false"  && !isUSPEP) {
            initialValue = false;
        } else if (value === "true"  && !isUSPEP) {
            initialValue = true;
        } else {
            initialValue = value;
        }
        if(key == 'sourceOfWealthBusinessActivities' || key == 'sourceOfWealthProfessionalOccupation' || key == 'sourceOfWealthInheritance' || key == 'sourceOfWealthOther') {
            if(value === undefined || value === '') {
                initialValue = false;
            }
        }
        
        const keys = key.split('.');
        if (keys.length > 1) {
            let nestedObj = initialValues[keys[0]] || {};
            if(keys[0] === 'headOfficeAddress' && keys[1] !== 'sameAsRegistered') {
                //@ts-ignore
                let personHeadOffice = person?.headOfficeAddress;
                nestedObj[keys.slice(1).join('.')] = personHeadOffice?.sameAsRegistered === 'true' ? '' : initialValue;
            } else {
                nestedObj[keys.slice(1).join('.')] = initialValue;
            }
            initialValues[keys[0]] = nestedObj;
        } else {
            initialValues[key] = initialValue;
        }
    
        validationSchema[key] = Yup.string();
    });
    
    
    const validation = Yup.object().shape(validationSchema);

    useEffect(() => {
        if (envs.admin) {
            dispatch(kycApplicationApprovalsGet({ params: { id: applicationId } }));
        }
    }, []);

    const formMappingsIndividual: Record<number, FormMapping> = {
        1: {
            component: KycPersonalDetailsForm,
            props: {
                type: 'individual',
                id: applicationId,
                person
            }
        },
        2: {
            component: KycContactForm,
            props:  {
                type: 'individual',
                person,
                id: applicationId
            }
        },
        3: {
            component: KycIdentificationForm,
            props: {
                type: 'individual',
                person,
                id: applicationId
            }
        },
        4: {
            component: KycSourceOfWealthForm,
            props: {}
        },
        5: {
            component: KycDeclarationsForm,
            props: {}
        },
        6: {
            component: KycCorrectnessDeclarationForm,
            props: {
                person: person,
            }
        }
    };
    const formMappingsCompany: Record<number, FormMapping> = {
        1: {
            component: KycCompanyDetailsForm,
            props: {}
        },
        2: {
            component: KycCompanyRegistrationAddress,
            props:  {}
        },
        3: {
            component: KycCompanyOperationsForm,
            props: {}
        },
        4: {
            component: KycCompanyFinancialsForm,
            props: {}
        },
        5: {
            component: KycPersonsListingForm,
            props: {
                type: 'authorized-persons',
                listing: 'authorized-persons'
            },
            steps: {
                1: {
                    component: KycPersonalDetailsForm,
                    props: {
                        showRoleType: true,
                        deleteForm: true
                    },
                    section: 'authorized-persons'
                },
                2: {
                    component: KycContactForm,
                    props: {
                        id: apUboID,
                        deleteForm: true
                    },
                    section: 'authorized-persons'
                },
                3: {
                    component: KycIdentificationForm,
                    props: {
                        id: apUboID,
                        deleteForm: true
                    },
                    section: 'authorized-persons'
                },
                4: {
                    component: KycCompanyDocumentsForm,
                    props: {
                        id: apUboID,
                        deleteForm: true,
                        isSavingHidden: true
                    },
                    section: 'authorized-persons'
                }
            }
        },
        6: {
            component: KycPersonsListingForm,
            props: {
                type: 'ubos',
                listing: 'ubos'
            },
            steps: {
                1: {
                    component: KycPersonalDetailsForm,
                    section: 'ubos',
                    props: {
                        deleteForm: true
                    }
                },
                2: {
                    component: KycContactForm,
                    props: {
                        id: apUboID,
                        deleteForm: true
                    },
                    section: 'ubos'
                },
                3: {
                    component: KycIdentificationForm,
                    props: {
                        id: apUboID,
                        deleteForm: true
                    },
                    section: 'ubos'
                },
                4: {
                    component: KycCompanyDocumentsForm,
                    props: {
                        id: apUboID,
                        deleteForm: true,
                        isSavingHidden: true
                    },
                    section: 'ubos'
                },
                5: {
                    component: KycSourceOfWealthForm,
                    props: {
                        person,
                        uboType: true,
                        deleteForm: true
                    },
                    section: 'ubos'
                },
                6: {
                    component: KycDeclarationsForm,
                    props: {
                        uboType: true,
                        deleteForm: true
                    },
                    section: 'ubos'
                },
            }
        },
        7: {
            component: KycCompanyDocumentsForm,
            props: {
                isSavingHidden: true
            },
            section: 'company'
        },
        8: {
            component: KycCompanySignatoriesForm,
            props: {}
        }
    };

    const currentStep = nav.nav.step ?? 0;
    const currentSubstep = nav.nav.substep;
    const apStep = stepsScheme.find(el => el.type === 'authorizedPersons')?.step;
	const uboStep = stepsScheme.find(el => el.type === 'beneficialOwners')?.step;
    const isApOrUbo = currentStep === apStep || currentStep === uboStep;
    const formMappings = envs.isBusiness ? formMappingsCompany : formMappingsIndividual;
    const curr = formMappings[currentStep];
    const { component: FormComponent, props: formProps, section: section } = curr && curr.steps ? currentSubstep ? curr.steps[currentSubstep] : curr : curr || formMappings[1];
    //@ts-ignore
    let substepErrors = kycApplication.validation?.find((err => err.refId === nav.nav.refId && err.substep === currentSubstep));
    let typeFromSection = section
    return (
        <BasicFormWrapper
            type={section}
            id={'form' + formProps?.link}
            initial={initialValues}
            validation={validation}
            dispatchSubmit={formUpdate}
            dispatchClear={formUpdateClear}
            params={isSection ? {applicationId: envs.profileId, sectionName: section, sectionId: apUboID} : {id: envs.profileId}}
            dispatchGet={formGet}
            successCondition={kycApplication.updatedKycApplication}
            saveInnerForm={false}
            hideSaving={((isApOrUbo) && currentSubstep === undefined) || formProps.isSavingHidden}
            deleteForm={formProps.deleteForm}
            transformBools={true}
            finalSubmit={currentStep === stepsScheme.slice(-1)[0].step}
            approveErrorsListing={errorsListing}
            validateButton={true}
            validationAction={validationAction}
            validatedElem={isApOrUbo ? substepErrors ? substepErrors.validationErrors : [] : kycApplication.validation[currentStep - 1] ? kycApplication.validation[currentStep - 1].validationErrors : []}
        >
            {({ handleInputChange, onSelectChange, onDateChange, values, errors, disabled, setFieldValue }) => (
                <FormComponent
                    {...formProps}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    printRender={printRender}
                    handleInputChange={handleInputChange}
                    onSelectChange={onSelectChange}
                    onDateChange={onDateChange}
                    disabled={disabled}
                    personType={section}
                    type={typeFromSection}
                />
            )}
        </BasicFormWrapper>
    );
};

export default KycFormInner;
