import * as Yup from 'yup'
import React, { FC, useEffect, useState, ReactNode } from 'react'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button, message, Drawer, Popover } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, InboxOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { countries } from 'countries-list'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormInput from '../ui-elements/FormInput'
import FormSelect from '../ui-elements/FormSelect'
import FormDate from '../ui-elements/FormDate'
import FormUpload from '../ui-elements/FormUpload'
import Well from '../ui-elements/Well'
import {FormCheckboxRadio, FormCheckboxRadioStandalone} from '../ui-elements/FormCheckboxRadio'
import { individualApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/individualApprovedFieldsGet'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { ButtonUpper } from '../ui-elements/Buttons'
import { DividerFirst } from '../ui-elements/Dividers'
import KycDocsInfo from '../KycDocsInfo'
import { Components, Paths } from "../../../api/kyc/generated/client"
import ApproveInput from "../../kyc-backend/ui-elements/Approve"
import { descriptions } from './common'


const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
    }
    input {
        position: relative;
        top: 3px;
    }
`

const Signature = styled.div`
    border: 1px solid ${themeColor.grayLight};
    padding: 5px 10px;
    height: 100px;
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
`

const Explanation = styled(Text)`
    font-size: 12px;
    color: ${themeColor.gray};
    display: block;
`

const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

export type addressType = Components.Schemas.AddressEntry | undefined;
export type documentEntry = Components.Schemas.IdentityDocumentEntry | undefined;

interface CProps {
    error?: string,
    children: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children}) => {
    return (
        <CheckboxWrapperStyles className='checkbox-wrapper'>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

interface KProps {
    printRender?: boolean,
    individual: any,
    individualGet: any,
    individualUpdate?: any,
    individualClearUpdate?: any,
    validationAction?: any,
    validationActionClear?: any
}

const KycIndividualDeclarationsForm: FC<KProps> = ({printRender, individual, individualGet, individualUpdate, individualClearUpdate, validationAction, validationActionClear}) => {
	const { Option } = Select;
    const envs = useSelector((state) => state.general.envs);
    const {kycApplication} = useSelector((state) => state.user2.kycApplication)
    const [fileEl, setFileEl] = useState();
    const [uploading, setUploading] = useState(false);
    const [visible, setVisible] = useState(false);
    const person = kycApplication.values;
    const status = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status)
    let initialValues = {
        personalDetails: {
            
            estimatedNetWorth: person.estimatedNetWorth ? person.estimatedNetWorth : '',
        },
        sourceOfWealthBusinessActivities: person.sourceOfWealthBusinessActivities === 'true' || false,
        sourceOfWealthProfessionalOccupation: person.sourceOfWealthProfessionalOccupation === 'true' || false,
        sourceOfWealthInheritance: person.sourceOfWealthInheritance === 'true' || false,
        sourceOfWealthOther: person.sourceOfWealthOther === 'true' || false,
        sourceOfWealthDescription: person.sourceOfWealthDescription || '',
        isPoliticallyExposedPerson: person.isPoliticallyExposedPerson?.toString() || 'false',
        isUSNationality: person.isUSNationality?.toString() || 'false',
        estimatedAmountOfEUR: person.estimatedAmountOfEUR ? person.estimatedAmountOfEUR : ''
    }
    const validation = Yup.object({
        personalDetails: Yup.object({     
            estimatedNetWorth: Yup.string(),
        }),
        isPoliticallyExposedPerson: Yup.string(),
        isUSNationality: Yup.string(),
        estimatedAmountOfEUR: Yup.string(),
        sourceOfWealthDescription: Yup.string()
    });

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const switchChange = (checked: boolean) => {
      console.log(`switch to ${checked}`);
    }
    useEffect(() => {
        if(envs.admin) {
            dispatch(individualApprovedFieldsGet({params: {individualId: envs.profileId}}))
        }
    }, [])
    const dateFormat = 'YYYY-MM-DD';
	return (
        (<BasicFormWrapper 
            id='dataIndividualValidationForm' 
            initial={initialValues} 
            validation={validation} 
            dispatchSubmit={individualUpdate} 
            dispatchClear={individualClearUpdate} 
            params={{individualId: envs.profileId}} 
            dispatchGet={individualGet} 
            successCondition={''} 
            saveInnerForm={false} 
            deleteForm={false} 
            transformBools={true} 
            validateButton={true}
            validationAction={validationAction} 
        >
            {({handleInputChange, onSelectChange, onDateChange, setFieldValue, values, errors, disabled}) => {
                return (<>
                    <DividerFirst className='divider-first'/>
                    <Title level={4}>Declarations</Title>
                    <div>
                        <Text><b>1. Beneficial Owners</b></Text><br/>
                        <Text>{descriptions.beneficialOwners}</Text>
                    </div>
                    <div>
                        <Row justify="space-between">
                            <Col>
                                <Text><b>2. Source of Wealth</b></Text>
                            </Col>
                            <Col>
                                {envs.admin &&
                                    <ApproveInput onSwitchChange={switchChange} name='sourceOfWealth' schemaParent='extraFields' schemaName='sourceOfWealth' type='individual' />
                                }
                            </Col>
                        </Row>
                        <Text>{descriptions.sourceOfWealth}:</Text>
                    </div>
                    <CheckboxWrapper error={errors?.sourceOfWealth}>
                        <FormCheckboxRadioStandalone id='sourceOfWealthBusinessActivities' type='checkbox' onChange={handleInputChange} label='Business activities (please name company name and business sector below)' disabled={disabled} error={errors?.sourceOfWealth}  />
                        <FormCheckboxRadioStandalone id='sourceOfWealthInheritance' type='checkbox' onChange={handleInputChange} label='Inheritance (please name testator and relationship below)' disabled={disabled} error={errors?.sourceOfWealth} />
                        <FormCheckboxRadioStandalone id='sourceOfWealthProfessionalOccupation' type='checkbox' onChange={handleInputChange} label='Professional occupation (please name employer and occupation below)' disabled={disabled} error={errors?.sourceOfWealth} />
                        <FormCheckboxRadioStandalone id='sourceOfWealthOther' type='checkbox' onChange={handleInputChange} label='Other (please provide details below)' disabled={disabled} error={errors?.sourceOfWealth} />
                    </CheckboxWrapper>
                    <FormInput label='Please describe the source of wealth: *' subLabel='(in 2-3 sentences)' className='sowIndividualPrint' subLabelClass='sowDescriptionIndividualPrint' name='sourceOfWealthDescription' schemaParent='extraFields'  schemaName='sourceOfWealthDescription' component='textarea' onChange={handleInputChange} value={values.sourceOfWealthDescription} disabled={disabled} printRender={printRender} error={errors?.sourceOfWealthDescription} type='individual' />
                    <FormCheckboxRadio label='Total estimated net worth ( EURO )' subLabel='(properties, cash, equity, etc.)' id='personalDetails.estimatedNetWorth' type='radio' onChange={handleInputChange}
                    options={[{
                            label: '< 100,000',
                            value: '0'
                        },
                        {
                            label: '100,000 - 1 milion',
                            value: '100000'
                        },
                        {
                            label: '1 - 5 milion',
                            value: '1000001'
                        },
                        {
                            label: '> 5 milion',
                            value: '5000001'
                        }
                    ]
                    } disabled={disabled} className="netWorthPrintIndividual" schemaParent='personalDetails' schemaName='estimatedNetWorth' approveType='individual' />
                    <label><b>3. {descriptions.estimatedExchangeAmountFuture}:</b></label>
                    <Row gutter={30}>
                        <Col md={12}>
                            <FormInput label='Amount (EUR)' name='estimatedAmountOfEUR' error={errors?.estimatedAmountOfEUR} onChange={handleInputChange} value={values.estimatedAmountOfEUR} schemaParent='extraFields' schemaName='estimatedAmountOfEUR' disabled={disabled} printRender={printRender} type='individual' formatting='numbers' />
                        </Col>
                    </Row>
                    <div>
                        <Text><b>4. Tax Compliance</b></Text><br/>
                        <Text>{descriptions.taxCompliance}</Text>
                    </div>
                    <div >
                        <Row justify="space-between">
                            <Col>
                                <Text><b>5. Politically Exposed Person</b></Text><QuestionCircleOutlined style={{'marginLeft': '5px'}} onClick={showDrawer} />
                            </Col>
                            <Col>
                                {envs.admin &&
                                    <ApproveInput onSwitchChange={switchChange} name='isPoliticallyExposedPerson' schemaParent='extraFields' schemaName='isPoliticallyExposedPerson' type='individual' />
                                }
                            </Col>
                        </Row>
                        <Text>Please tick <b>ONE</b> of below options.</Text>
                    </div>
                    <div>
                        <CheckboxWrapper>
                            <FormCheckboxRadio id='isPoliticallyExposedPerson' type='radio' onChange={handleInputChange}
                                options={[{
                                        label: descriptions.nonPoliticallyExposedPerson + descriptions.politicallyExposedPersonExplanation,
                                        value: 'false'
                                    },
                                    {
                                        label: `${descriptions.politicallyExposedPerson}.`,
                                        value: 'true'
                                    }
                                ]
                            } disabled={disabled} showApprove={false} approveType='individual' />
                        </CheckboxWrapper>
                        <Drawer title="Politically Exposed Person" placement="right" onClose={onClose} open={visible}>
                            <p>Prominent public functions being:</p>
                            <p>a) Head of State or of government, ministers, deputy and secretaries of ministers, and high-ranked party officials; </p>
                            <p>b) Members of Parliament and similar legislative functions;</p>
                            <p>c) Members of supreme courts, constitutional courts and other high ranking courts, against which – except in extraordinary cases – there is no appeal;</p> 
                            <p>d) Auditors or directors of Central Banks;</p> 
                            <p>e) Ambassadors, consulates and high ranking officers of the armed forces;</p>
                            <p>f) Members of administrative, management or supervisory bodies of state owned enterprises;</p> 
                            <p>g) Directors, deputy directors and members of senior management and similar officials of international governmental organizations.</p>
                        </Drawer>
                   </div>
                    <div>
                         <Row justify="space-between">
                             <Col>
                                 <Text><b>6. US Person</b></Text>
                             </Col>
                             <Col>
                                 {envs.admin &&
                                     <ApproveInput onSwitchChange={switchChange} name='isUSNationality' schemaParent='extraFields' schemaName='isUSNationality' type='individual' />
                                 }
                             </Col>
                         </Row>
                         <Text>Please tick <b>ONE</b> of below options.</Text>
                     </div>
                    <CheckboxWrapper>
                        <FormCheckboxRadio id='isUSNationality' type='radio' onChange={handleInputChange}
                            options={[{
                                    label: descriptions.nonUSNationality + descriptions.uSNationalityExplanation,
                                    value: 'false'
                                },
                                {
                                    label: `${descriptions.uSNationality}.`,
                                    value: 'true'
                                }
                            ]
                        } disabled={disabled} showApprove={false} approveType='individual' />
                    </CheckboxWrapper>
                    <div style={!printRender ? {'marginBottom': '15px'} : {}}>
                        <Text><b>7. Correctness and changes</b></Text><br/>
                        <Text>{descriptions.correctness1}<br />{descriptions.correctness2} <b>{descriptions.correctness3}</b></Text>
                    </div>
                    {printRender && (
                        <>
                            <br />
                            <Text><b>Customer's signature:</b></Text>
                            <Row>
                                <Col><Signature><span>{person.firstName} {person.lastName}</span></Signature></Col>
                            </Row>
                        </>
                    )}
                </>);
            }}
        </BasicFormWrapper>)
    );
}

export default KycIndividualDeclarationsForm
