import * as Yup from 'yup'
import React, { FC, useEffect, useState, ReactNode } from 'react'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button, message, Drawer, Popover } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, InboxOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { countries } from 'countries-list'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { APResponse } from '../../../store/kyc-front/slices/authorizedPersonsSlice'//leave response
import { uboDocsGetClear, uboDocUrlClear, uboDocCreateClear } from '../../../store/kyc-front/slices/uboDocsSlice'
import { uboBackDocUrlGetClear, uboBackDocsGetClear } from '../../../store/kyc-backend/slices/uboDocsSlice'
import { ubosApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/ubosApprovedFieldsGet'
import { UboResponse } from '../../../store/kyc-front/slices/ubosSlice'//leave response
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormInput from '../ui-elements/FormInput'
import FormSelect from '../ui-elements/FormSelect'
import FormDate from '../ui-elements/FormDate'
import Well from '../ui-elements/Well'
import { FormCheckboxRadio, FormCheckboxRadioStandalone } from '../ui-elements/FormCheckboxRadio'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { ButtonUpper } from '../ui-elements/Buttons'
import { descriptions } from './common'
import KycProfileFormModule from './KycProfileFormModule'
import KycDocsInfo from '../KycDocsInfo'
import { Components, Paths } from "../../../api/kyc/generated/client"
import ApproveInput from "../../kyc-backend/ui-elements/Approve"


const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
        color: ${themeColor.gray}
    }
    input {
        position: relative;
        top: 3px;
    }
    &.non-print10 {
        margin-top: 10px;
    }
`

interface KProps {
    person: UboResponse,
    printRender?: boolean,
    ubosGet?: any,
    uboUpdate?: any,
    uboClearUpdate?: any,
    uboArchive?: any,
    uboClearArchive?: any,
    uboDocsGet?: any,
    uboDocCreate?: any,
    docUrlGet?: any,
    deleteForm?: boolean,
    restoreForm?: boolean,
    uboDocArchiveClear?: (props: any) => any,
    uboDocArchive?: (props: any) => any,
    validationAction?: any,
    validationActionClear?: any
}

const Explanation = styled(Text)`
    font-size: 12px;
    color: ${themeColor.gray};
    display: block;
`
const DeclarationWrapper = styled.div`
    margin-top: 20px;
`
const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

interface CProps {
    error?: string,
    className?: string,
    children: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children, className}) => {
    return (
        <CheckboxWrapperStyles className={className}>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

//type uboUpdate = Components.Schemas.UpdateBeneficialOwner | undefined;
export type addressType = Components.Schemas.AddressEntry | undefined;
export type documentEntry = Components.Schemas.IdentityDocumentEntry | undefined;

const KycUboForm: FC<KProps> = ({person, printRender, uboDocsGet, uboDocCreate, docUrlGet, uboDocArchive, uboDocArchiveClear, ubosGet, deleteForm, restoreForm, uboUpdate, uboClearUpdate, uboArchive, uboClearArchive, validationAction, validationActionClear}) => {
	const { Option } = Select;
    const [initialErrors, setInitialErrors] = useState({});
    const [errorsLoaded, setErrorsLoaded] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    const status = useSelector((state) => envs.admin ?  state.admin.backStatus : state.user.status); 
    const uboDocs = useSelector((state) => envs.admin ? state.admin.backUboDocs : state.user.uboDocs); 
    const [fileEl, setFileEl] = useState();
    const [uploading, setUploading] = useState(false);
    const [visible, setVisible] = useState(false);
    const residentialAddress: addressType = person.residentialAddress;
    const identityDocument: documentEntry = person.identityDocument;
    useEffect(() => {
        if(envs.admin) {
            dispatch(ubosApprovedFieldsGet({params: {companyId: envs.profileId, beneficialOwnerId: person.beneficialOwnerId}}))
        }
    }, [])
    let initialValues = {
        personalDetails: {
            firstName: person.firstName || '',
            lastName: person.lastName || '',
            dateOfBirth: person.dateOfBirth || undefined,
            placeOfBirth: person.placeOfBirth || '',
            residentialAddress: {
                address: residentialAddress?.address || '',
                postcode: residentialAddress?.postcode || '',
                city: residentialAddress?.city || '',
                country: residentialAddress?.country || ''
            },
            nationality: person.nationality || '',
            occupation: person.occupation || '',
            email: person.email || '',
            phone: person.phone || '',
            identityDocument: {
                kind: identityDocument?.kind || 'PassportKind',
                number: identityDocument?.number || '',
                placeOfIssue: identityDocument?.placeOfIssue || '',
                dateOfIssue: identityDocument?.dateOfIssue || undefined,
                dateOfExpiry: identityDocument?.dateOfExpiry || undefined,
                nationality: identityDocument?.nationality || ''
            },
            estimatedNetWorth: person.estimatedNetWorth ? person.estimatedNetWorth : '',
        },
        roleType: person.roleType || undefined,
        sourceOfWealthBusinessActivities: person.sourceOfWealthBusinessActivities || false,
        sourceOfWealthProfessionalOccupation: person.sourceOfWealthProfessionalOccupation || false,
        sourceOfWealthInheritance: person.sourceOfWealthInheritance || false,
        sourceOfWealthOther: person.sourceOfWealthOther || false,
        sourceOfWealthDescription: person.sourceOfWealthDescription || '',
        isPoliticallyExposedPerson: person.isPoliticallyExposedPerson?.toString() || 'false',
        estimatedAmountOfEUR: 'x',
        isUSNationality: person.isUSNationality?.toString() || 'false'
    }
     useEffect(() => {
        /*let uboValidation = status.updatedStatus.beneficialOwnersValidated;
        let errors = {};
        if(uboValidation && errorsLoaded === false) {
            uboValidation.forEach((error, i) => {
                if(!_.isEmpty(error.validationErrors) && (error.entry === person.beneficialOwnerId)) {
                    //@ts-ignore
                    errors[error.validationErrors?.EmptyField?.name] = 'Field is required'
                }
            })
            setInitialErrors(errors);
            setErrorsLoaded(true);
           
        }*/
        
    })
    const validation = Yup.object({
        personalDetails: Yup.object({      
            firstName: Yup.string(),
            lastName: Yup.string(),
            dateOfBirth: Yup.string(),
            placeOfBirth: Yup.string(),
            residentialAddress: Yup.object({
                address: Yup.string(),
                postcode: Yup.string(),
                city: Yup.string(),
                country: Yup.string()
            }),
            nationality: Yup.string(),
            occupation: Yup.string(),
            email: Yup.string().email('Please provide valid email address'),
            phone: Yup.string(),
            estimatedNetWorth: Yup.string(),
            identityDocument: Yup.object({
                kind: Yup.string(),
                number: Yup.string(),
                placeOfIssue: Yup.string(),
                dateOfIssue: Yup.string(),
                dateOfExpiry: Yup.string(),
                nationality: Yup.string()
            })
        }),
        roleType: Yup.string(),
        sourceOfWealthBusinessActivities: Yup.boolean(),
        sourceOfWealthProfessionalOccupation: Yup.boolean(),
        sourceOfWealthInheritance: Yup.boolean(),
        sourceOfWealthOther: Yup.boolean(),
        sourceOfWealthDescription: Yup.string(),
        isPoliticallyExposedPerson: Yup.string(),
        isUSNationality: Yup.string()
    });

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const switchChange = (checked: boolean) => {
      console.log(`switch to ${checked}`);
    }
    const dateFormat = 'YYYY-MM-DD';
    const personIsArchived = person.archivedAt ? true : false;
	return (
        (<BasicFormWrapper 
            id={person.beneficialOwnerId} 
            type='ubos'
            initial={initialValues} 
            initialErrors={initialErrors} 
            validation={validation} 
            dispatchSubmit={uboUpdate} 
            dispatchClear={uboClearUpdate} 
            params={{companyId: envs.profileId, beneficialOwnerId: person.beneficialOwnerId}} 
            dispatchGet={ubosGet} 
            successCondition={false} 
            saveInnerForm={true} 
            deleteForm={deleteForm === false ? false : true} 
            restoreForm={restoreForm}
            transformBools={true}  
            printRender={printRender}
            validationAction={validationAction} 
        >
            {({handleInputChange, onSelectChange, onDateChange, setFieldValue, values, errors, disabled, initialErrorsValues}) => {
                return (
                    (<div className='marginUboPrint'>
                        <KycProfileFormModule 
                            person={person} 
                            type='ubo'
                            personType={'ubos'}
                            //@ts-ignore
                            docs={uboDocs.uboDocs.find(doc => doc.id === person.beneficialOwnerId)} 
                            //@ts-ignore
                            newDoc={uboDocs.newUboDoc}
                            docsGet={uboDocsGet}
                            docGetClear={envs.admin ? uboBackDocsGetClear : uboDocsGetClear}
                            docsGetParams={{ companyId: envs.profileId, beneficialOwnerId: person.beneficialOwnerId }}
                            docCreate={uboDocCreate}
                            docClear={uboDocCreateClear}
                            docUrlGet={docUrlGet} 
                            docUrlClear={envs.admin ? uboBackDocUrlGetClear : uboDocUrlClear}
                            docUrlResp={uboDocs.uboDocUrl.find(url => url.id === person.beneficialOwnerId)}
                            docDelete={uboDocArchive}
                            docDeleteClear={uboDocArchiveClear}
                            values={values} 
                            errors={errors} 
                            handleInputChange={handleInputChange} 
                            onSelectChange={onSelectChange} 
                            onDateChange={onDateChange} 
                            setFieldValue={setFieldValue} 
                            disabled={disabled} 
                            printRender={printRender} 
                        />
                        <Divider />
                        <Title className='marginUboHeaderPrint' level={4}>Declarations</Title>
                        <DeclarationWrapper className='print10'>
                            <Row justify="space-between">
                                <Col>
                                    <Text><b>1. Beneficial Owners</b>*</Text>
                                </Col>
                                <Col>
                                    {envs.admin && !personIsArchived &&
                                        <ApproveInput onSwitchChange={switchChange} name='roleType' schemaParent='extraFields' schemaName='roleType' type='ubos' personId={person.beneficialOwnerId} />
                                    }
                                </Col>
                            </Row>
                            <Text>{descriptions.beneficialOwnersUbo1} <b>{descriptions.beneficialOwnersUbo2}</b></Text>
                        </DeclarationWrapper>
                        <CheckboxWrapper error={errors?.personalDetails?.roleType} className='checkbox-wrapper'>
                            <FormCheckboxRadio id='roleType' type='radio' onChange={handleInputChange}
                                options={[{
                                        label: descriptions.roleTypeHoldsMoreThan25PercentageOfShares,
                                        value: 'HoldsMoreThan25PercentageOfShares'
                                    },
                                    {
                                        label: descriptions.roleTypeUltimatelyControlOverBusiness,
                                        value: 'UltimatelyControlOverBusiness'
                                    },
                                    {
                                        label: descriptions.roleTypeMemberOfGoverningBody,
                                        value: 'MemberOfGoverningBody'
                                    }
                                ]
                            } disabled={disabled} showApprove={false} error={errors?.personalDetails?.roleType} approveType='ubos' />
                        </CheckboxWrapper>
                        <DeclarationWrapper className='print0'>
                            <Row justify="space-between">
                                <Col>
                                    <Text><b>2. Source of Wealth</b>*</Text>
                                </Col>
                                <Col>
                                    {envs.admin && !personIsArchived &&
                                        <ApproveInput onSwitchChange={switchChange} name='sourceOfWealth' schemaParent='extraFields' schemaName='sourceOfWealth' type='ubos' personId={person.beneficialOwnerId}/>
                                    }
                                </Col>
                            </Row>
                            <Text>{descriptions.sourceOfWealthUbo}:</Text>
                        </DeclarationWrapper>
                        <CheckboxWrapper className='non-print10 checkbox-wrapper' error={errors?.personalDetails?.sourceOfWealth}>
                            <FormCheckboxRadioStandalone id='sourceOfWealthBusinessActivities' type='checkbox' onChange={handleInputChange} label='Business activities (please name company name and business sector below)' disabled={disabled} error={errors?.personalDetails?.sourceOfWealth} />
                            <FormCheckboxRadioStandalone id='sourceOfWealthInheritance' type='checkbox' onChange={handleInputChange} label='Inheritance (please name testator and relationship below)' disabled={disabled} error={errors?.personalDetails?.sourceOfWealth} />
                            <FormCheckboxRadioStandalone id='sourceOfWealthProfessionalOccupation' type='checkbox' onChange={handleInputChange} label='Professional occupation (please name employer and occupation below)' disabled={disabled} error={errors?.personalDetails?.sourceOfWealth} />
                            <FormCheckboxRadioStandalone id='sourceOfWealthOther' type='checkbox' onChange={handleInputChange} label='Other (please provide details below)' disabled={disabled} error={errors?.personalDetails?.sourceOfWealth} />
                        </CheckboxWrapper>
                        <FormInput subLabelClass='sowDescriptionPrint' label='Please describe the source of wealth: *' className='sowIndividualPrint' subLabel='(in 2-3 sentences)' name='sourceOfWealthDescription' schemaParent='extraFields'  schemaName='sourceOfWealthDescription' type='ubos' personId={person.beneficialOwnerId} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.sourceOfWealthDescription} printRender={printRender} error={errors?.personalDetails?.sourceOfWealthDescription} showApprove={!personIsArchived} />
                        <FormCheckboxRadio label='Total estimated net worth ( EURO ) *' subLabel='(properties, cash, equity, etc.)' id='personalDetails.estimatedNetWorth' type='radio' onChange={handleInputChange}
                        options={[{
                                label: '< 100,000',
                                value: '0'
                            },
                            {
                                label: '100,000 - 1 milion',
                                value: '100000'
                            },
                            {
                                label: '1 - 5 milion',
                                value: '1000001'
                            },
                            {
                                label: '> 5 milion',
                                value: '5000001'
                            }
                        ]
                        } disabled={disabled} className='checkbox-space netWorthPrint' error={errors?.personalDetails?.estimatedNetWorth} schemaParent='personalDetails' schemaName='estimatedNetWorth' approveType='ubos' personId={person.beneficialOwnerId} showApprove={!personIsArchived} />
                        <DeclarationWrapper className='print20'>
                            <Text><b>3. Tax Compliance</b></Text><br/>
                            <Text>{descriptions.taxCompliance}</Text>
                        </DeclarationWrapper>
                        <DeclarationWrapper className='print10'>
                            <Row justify="space-between">
                                <Col>
                                    <Text><b>4. Politically Exposed Person</b></Text><QuestionCircleOutlined style={{'marginLeft': '5px'}} onClick={showDrawer} /><br/>
                                </Col>
                                <Col>
                                    {envs.admin && !personIsArchived &&
                                        <ApproveInput onSwitchChange={switchChange} name='isPoliticallyExposedPerson' schemaParent='extraFields' schemaName='isPoliticallyExposedPerson' type='ubo' personId={person.beneficialOwnerId}/>
                                    }
                                </Col>
                            </Row>
                            <Text>Please tick <b>ONE</b> of below options.</Text>
                        </DeclarationWrapper>
                        <div>
                            <CheckboxWrapper className='checkbox-wrapper'>
                                <FormCheckboxRadio id='isPoliticallyExposedPerson' type='radio' onChange={handleInputChange}
                                    options={[{
                                            label: descriptions.nonPoliticallyExposedPerson + descriptions.politicallyExposedPersonExplanation,
                                            value: 'false'
                                        },
                                        {
                                            label: `${descriptions.politicallyExposedPerson}.`,
                                            value: 'true'
                                        }
                                    ]
                                } disabled={disabled} showApprove={false} error={errors?.personalDetails?.isPoliticallyExposedPerson} approveType='ubos' />
                            </CheckboxWrapper>
                            <Drawer title="Politically Exposed Person" placement="right" onClose={onClose} open={visible}>
                                <p>Prominent public functions being:</p>
                                <p>a) Head of State or of government, ministers, deputy and secretaries of ministers, and high-ranked party officials; </p>
                                <p>b) Members of Parliament and similar legislative functions;</p>
                                <p>c) Members of supreme courts, constitutional courts and other high ranking courts, against which – except in extraordinary cases – there is no appeal;</p> 
                                <p>d) Auditors or directors of Central Banks;</p> 
                                <p>e) Ambassadors, consulates and high ranking officers of the armed forces;</p>
                                <p>f) Members of administrative, management or supervisory bodies of state owned enterprises;</p> 
                                <p>g) Directors, deputy directors and members of senior management and similar officials of international governmental organizations.</p>
                            </Drawer>
                       </div>
                        <div>
                             <Row justify="space-between">
                                 <Col>
                                     <Text><b>5. US Person</b></Text>
                                 </Col>
                                 <Col>
                                     {envs.admin && !personIsArchived &&
                                         <ApproveInput onSwitchChange={switchChange} name='isUSNationality' schemaParent='extraFields' schemaName='isUSNationality' type='ubos' personId={person.beneficialOwnerId}/>
                                     }
                                 </Col>
                             </Row>
                             <Text>Please tick <b>ONE</b> of below options.</Text>
                         </div>
                        <CheckboxWrapper className='checkbox-wrapper'>
                            <FormCheckboxRadio id='isUSNationality' type='radio' onChange={handleInputChange}
                                options={[{
                                        label: descriptions.nonUSNationality + descriptions.uSNationalityExplanation,
                                        value: 'false'
                                    },
                                    {
                                        label: `${descriptions.uSNationality}.`,
                                        value: 'true'
                                    }
                                ]
                            } disabled={disabled} showApprove={false} error={errors?.personalDetails?.isUSNationality} approveType='ubos' />
                        </CheckboxWrapper>
                        <div>
                            <Text><b>6. Correctness and changes</b></Text><br/>
                            <Text>{descriptions.correctness1}<br />{descriptions.correctness2} <b>{descriptions.correctness3}</b></Text>
                        </div>
                        {!printRender && <div style={{'marginBottom': '30px'}}></div>}
                    </div>)
                );
            }}
        </BasicFormWrapper>)
    );
}

export default KycUboForm
