import React, { FC, useEffect, useState } from 'react'
import { Divider, Space, Row, Col, Select, Table, Typography, Tag,Popconfirm, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import { riskProfileCompanySnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotsGet'
import { riskProfileIndividualSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotsGet'
import { riskProfileCompanyGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyGet'
import { riskProfileIndividualGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualGet'
import { riskProfileCompanyArchive } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyArchive'
import { riskProfileIndividualArchive } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualArchive'
import { 
    riskProfileCompanyArchiveClear,
    riskProfileIndividualArchiveClear
} from '../../../../store/kyc-backend/slices/riskSlice'
import TableElement from '../../../kyc-front/ui-elements/TableElement'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { themeColor } from '../../../../styles/themeStyles'

interface CProps {
    handleHistoryShow: any
}


const RiskProfileHistoryTable: FC<CProps> = ({handleHistoryShow}) => {
    const envs = useSelector((state) => state.general.envs);
    const riskProfile = useSelector((state) => state.admin.riskProfile);
    const isBusiness = envs.accountType === 'BUSINESS';
    const [deleting, setDeleting] = useState(false);
    const snapshotsHistory = isBusiness ? riskProfile.riskProfileCompanySnapshots : riskProfile.riskProfileIndividualSnapshots;
    const { Text } = Typography;
    const navigate = useNavigate();
    const pathParams = useParams();
    const [popupVisible, setPopupVisible] = useState('');
    const profileGettingStatus = useActionStatus(isBusiness ? riskProfileCompanyGet : riskProfileIndividualGet);
    //@ts-ignore
    const approvedExists = snapshotsHistory.find((snap) => snap.isLatestApproved);
    useEffect(() => {
        if(isBusiness) {
            dispatch(riskProfileCompanySnapshotsGet({ params: { companyId: envs.profileId} }));
            if(!profileGettingStatus.isFullfilled) dispatch(riskProfileCompanyGet({params: {companyId: envs.profileId}}));
        } else {
            dispatch(riskProfileIndividualSnapshotsGet({ params: { individualId: envs.profileId} }));
            if(!profileGettingStatus.isFullfilled) dispatch(riskProfileIndividualGet({params: {individualId: envs.profileId}}))
        }
    }, []);
    useEffect(() => {
        if(deleting) {
            if(riskProfile.riskProfileCompanyArchived) {
                message.success('This element was successfully deleted');
                dispatch(riskProfileCompanySnapshotsGet({ params: { companyId: envs.profileId} }));
                setDeleting(false);
                
            }
        }
    }, [riskProfile.riskProfileCompanyArchived]);
     useEffect(() => {
        if(deleting) {
            if(riskProfile.riskProfileIndividualArchived) {
                message.success('This element was successfully deleted');
                dispatch(riskProfileIndividualSnapshotsGet({ params: { individualId: envs.profileId} }));
                setDeleting(false);
                
            }
        }
    }, [riskProfile.riskProfileIndividualArchived]);
	return (
		<div>
                <br />
                {snapshotsHistory.length ? (
                    <TableElement
                        sortersListProps={[]}
                        sortersState={{}}
                    >
                        {({onTableChange}) => {
                            const columns = (showHistory: any) => ([
                              {
                                title: 'Created at',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                render: (created: string) => <span>{moment(created).format('YYYY-MM-DD HH:mm:ss')}</span>,
                              },
                              {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                render: (status: any) => <Tag color={status === 'Approved' ? themeColor.green : status === 'Drafted' ? themeColor.blue : status === 'Archived approved' ? themeColor.greenGray : status === 'Archived draft' ? themeColor.blueGray : themeColor.gray}>{status}</Tag>,
                              },
                              {
                                title: 'Version',
                                dataIndex: 'dataVersion',
                                key: 'dataVersion',
                                render: (version: any) => <span>{version}</span>,
                              },
                              {
                                title: 'Actions',
                                key: 'action',
                                render: (el: any) => (
                                    <Space size="middle">
                                        <ButtonUpper className='btn-sm' btnType='primary' onClick={() => showHistory(el)} disabled={el.current ? true : false}>{el.status === 'Drafted' ? el.current ? 'Editing' : 'Edit' : el.current ? 'Previewing' : 'Preview'}</ButtonUpper>
                                        <Popconfirm
                                            title="Are You sure you want to delete this Risk Profile?"
                                            visible={popupVisible === el.id}
                                            onVisibleChange={() => handleConfirmVisibleChange(el.id)}
                                            onConfirm={() => deleteSnapshot(el)}
                                            onCancel={cancelConfirm}
                                            okText="Yes, delete"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper className='btn-sm' btnType='primary' ghost disabled={el.current ? true : el.isLatestApproved ? true : false}>Delete</ButtonUpper>
                                        </Popconfirm>

                                    </Space>
                                ),
                              },
                            ]);
                            const handleConfirmVisibleChange = (id: any) => {
                                setPopupVisible(id);
                            }
                            const cancelConfirm = () => {
                                setPopupVisible('');
                            }
                            const showHistory = (el: any) => {
                                handleHistoryShow(snapshotsHistory.find(snap => snap.snapshotId === el.id));
                                if(el.isApproved || (el.isEditable && approvedExists === undefined)) {
                                    navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile');
                                } else {
                                    navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile/' + el.id);
                                }
                                
                            }
                            const deleteSnapshot = (el: any) => {
                                setPopupVisible('');
                                if(isBusiness) {
                                    dispatch(riskProfileCompanyArchive({params: {companyId: envs.profileId, riskProfileSnapshotId: el.id}, data: null}))
                                } else {
                                    dispatch(riskProfileIndividualArchive({params: {individualId: envs.profileId, riskProfileSnapshotId: el.id}, data: null}))
                                }
                                setDeleting(true);
                            }
                            return (
                                <Table
                                    onChange={onTableChange} 
                                    columns={columns(showHistory)}
                                    dataSource={snapshotsHistory.map((profile, i) => {
                                        //@ts-ignore
                                        return {
                                            createdAt: profile.createdAt, 
                                            dataVersion: 'V1',
                                            //@ts-ignore
                                            //dataVersion: profile.dataVersion.RiskProfileV1 ? 'V1' : '', 
                                            id: profile.snapshotId, 
                                            //@ts-ignore
                                            isApproved: profile.isLatestApproved, 
                                            //@ts-ignore
                                            isEditable: profile.isEditable,
                                             //@ts-ignore
                                            current: profile.snapshotId === pathParams[1] || pathParams[1] === undefined && profile.isLatestApproved || (pathParams[1] === undefined && profile.isEditable && approvedExists === undefined),
                                            //@ts-ignore
                                            status: profile.isLatestApproved ? 'Approved' : profile.isEditable ? 'Drafted' : profile.isApproved ? 'Archived approved' : 'Archived draft'
                                        }
                                    })}
                                />
                            )
                        }}
                    </TableElement>
                ) : (<div></div>)}
        </div>
	)
};

export default RiskProfileHistoryTable;