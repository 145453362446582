import { useSelector } from './useSelector'

type IReturn = {
	isApprovalDisabled: boolean
	disabledApprovalMsg? : string
}
export const useKycApprove = (): IReturn => {
	const {kycApplication} = useSelector(state=>state.user2.kycApplication)
	const isApprovalDisabled = kycApplication.status !== 'SUBMITTED'
	const isKycAccepted = kycApplication.status === 'ACCEPTED'
	const disabledApprovalMsg = isApprovalDisabled ? isKycAccepted ? 'Application has been approved' : 'Application is not submitted': undefined
	
	return {
		isApprovalDisabled,
		disabledApprovalMsg,
	}
}