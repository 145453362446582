import { Table, Space, Typography, Row, Col, Empty } from 'antd'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import { PageContent } from 'modules/layout/PageContent'
import { dispatch } from 'store/store'
import React, { FC, useEffect, useState} from 'react'
import { momentNew } from 'modules/kyc-front/ui-elements/FormDate'
import KycLayout, { NavCustom } from 'modules/kyc-front/kyc-layout/KycLayout'
import { ButtonUpper } from 'modules/kyc-front/ui-elements/Buttons'
import TableElement from 'modules/kyc-front/ui-elements/TableElement'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { setNav } from 'store/kyc-front/slices/navSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { themeColor } from 'styles/themeStyles'
import { CheckCircleOutlined, CloseOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { clientsGet } from 'store/kyc-backend/asyncThunks/clientsGet'
import { FormInputStandalone } from 'modules/kyc-front/ui-elements/FormInput'
import { Components } from 'api/kyc/generated/client2'
import { clientsGetClear } from 'store/kyc-backend/slices/clientsSlice'

const { Text } = Typography

const NavCustomStyled = styled(NavCustom)`
    position: absolute;
    top: -40px;
    z-index: 0;
    padding: 0;
    border: 0;
`

const TableCustom = styled(Table)`
    .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        font-size: 15px;
        margin-top: -5px;
    }
`
const ButtonUpperTabs = styled(ButtonUpper)`
    margin-top: -1px;
	&.btn-sm {
		color: ${themeColor.gray};
        padding: 10px 15px;
        text-transform: none;
        font-size: 14px;    
		&.active {
			color: ${themeColor.primaryColor};
			background-color: ${themeColor.grayWhite};
		}
	}
`

const ButtonUpperTabsSearch = styled(ButtonUpperTabs)`
    padding: 9px 9px 7px 9px!important;
    top: 1px;
    form {
        display: flex;
    }
    input {
        position: relative;
        top: 1px;
        height: 25px;
        min-width: 250px;
        padding-right: 24px;
    }
    label {
        display: none;
    }
    .anticon {
        margin-left: 5px;
        cursor: pointer;
    }
    .anticon-close {
        position: absolute;
        right: 35px;
        top: 15px;
    }
`

interface CProps {
    listingType: ListingType
}
type ListingType = 'leads' | 'active' | 'closed' | 'search'

type ProfileResponse = Components.Schemas.ClientSummary

interface TProps {
    listingType: ListingType,
    sortersList: string[],
    activeTab: string,
    fieldValue: string,
    fieldValueResult?: string
}

const ClientsTable: FC<TProps> = ({sortersList, listingType, activeTab, fieldValue, fieldValueResult}) => {
    const navigate = useNavigate()
    const envs = useSelector((state) => state.general.envs)
    const clients = useSelector((state) => state.admin2.clients)
    const {apiURL, admin, adminSuffix, type, env, routeSuffix} = envs
    return (
        <TableElement
            sortersListProps={['']}
            sortersState={{
                createdAt: 'descend'
            }}
            filtersState={{name: false,
                accountType: '',
                reviewStatus: '',
                activeProducts: '',
                businessRelation: ''
            }}
        >
            {({onTableChange, getColumnFilterProps, defaultCurrent, sorters, filters}) => {
                const columns = (redirectToUser: any) => {
                    const baseColumns = [
                        {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text: string) => <b>{text}</b>,
                        },
                        {
                        title: 'Account type',
                        dataIndex: 'accountType',
                        key: 'accountType',
                        ...getColumnFilterProps('accountType', [
                            {
                            text: 'COMPANY',
                            value: 'COMPANY',
                            },
                            {
                            text: 'INDIVIDUAL',
                            value: 'INDIVIDUAL',
                            },
                        ]),
                        onFilter: (value: string, record: any) => record.accountType.startsWith(value),
                        render: (relation: any) => <span>{relation}</span>,
                            width: '160px',
                        },
                        {
                        title: 'Created at',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        sortOrder: sorters.createdAt,
                        //@ts-ignore
                        sorter: (a: any, b: any) => new Date(a.createdAt) - new Date(b.createdAt),
                        render: (created: string) => <span>{momentNew({time: created, detailed: true})}</span>,
                            width: '180px',
                        },
                        {
                        title: 'Action',
                        key: 'action',
                        render: (el: any) => (
                            <Space size="middle">
                            <ButtonUpper className="btn-sm" btnType="primary" onClick={() => redirectToUser(el)} ghost>
                                Review
                            </ButtonUpper>
                            </Space>
                        ),
                            width: '130px',
                        },
                    ]
                    if (listingType === 'leads' || listingType === 'closed' || listingType === 'search') {
                        const kycStatusColumn = {
                            title: 'KYC status',
                            dataIndex: 'reviewStatus',
                            key: 'reviewStatus',
                            ...getColumnFilterProps('reviewStatus', [
                                {
                                    text: 'REJECTED',
                                    value: 'REJECTED',
                                },
                                {
                                    text: 'ACCEPTED',
                                    value: 'ACCEPTED',
                                },
                                {
                                    text: 'CLARIFICATION_NEEDED',
                                    value: 'CLARIFICATION_NEEDED',
                                },
                                {
                                    text: 'SUBMITTED',
                                    value: 'SUBMITTED',
                                },
                                {
                                    text: 'NEW',
                                    value: 'NEW',
                                }
                            ]),
                            onFilter: (value: string, record: any) =>
                            record.reviewStatus ? record.reviewStatus.startsWith(value) : value === 'Unknown' ? value === 'Unknown' : false,
                            render: (status: any) => <span>{status ? status : '-'}</span>,
                            width: '200px',
                        }
                        baseColumns.splice(3, 0, kycStatusColumn)
                    }
                    if (listingType === 'active' || listingType === 'closed' || listingType === 'search') {
                        const productsColumn = {
                        title: 'Products',
                        dataIndex: 'activeProducts',
                        key: 'activeProducts',
                        ...getColumnFilterProps('activeProducts', [
                            {
                            text: 'FX',
                            value: 'FX',
                            },
                            {
                            text: 'BPG',
                            value: 'BPG',
                            },
                        ]),
                        onFilter: (value: string, record: any) => record.activeProducts?.includes(value),
                        render: (types: string[]) => (
                            <span>
                            {types.map((type, index) => (
                                <span key={index}>
                                {type}
                                {index < types.length - 1 && <span> | </span>}
                                </span>
                            ))}
                            </span>
                        ),
                            width: '110px',
                        }
                        baseColumns.splice(2, 0, productsColumn)
                        const businessRelationColumn = {
                        title: 'Business Relation',
                        dataIndex: 'businessRelation',
                        key: 'businessRelation',
                        ...getColumnFilterProps('businessRelation', [
                            {
                            text: 'ACTIVE',
                            value: 'ACTIVE',
                            },
                            {
                            text: 'INACTIVE',
                            value: 'INACTIVE',
                            },
                        ]),
                        onFilter: (value: string, record: any) => record.businessRelation === 'ACTIVE' && value === 'ACTIVE' ? true : record.businessRelation === 'INACTIVE' && value === 'INACTIVE' ? true : false,
                        render: (relation: any) => (
                            <span>
                            {relation === 'ACTIVE' ? <><Text type='success'><CheckCircleOutlined style={{marginRight: 8}} /></Text>{relation}</> : <><Text type='warning'><MinusCircleOutlined style={{marginRight: 8}} /></Text>{relation}</> }
                            </span>
                        ),
                            width: '180px',
                        }
                        baseColumns.splice(4, 0, businessRelationColumn)
                    }
                    
                    return baseColumns
                    }
                const redirectToUser = (el: any) => {
                    dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: el.profileId, accountType: el.accountType === 'COMPANY' ? 'BUSINESS' : 'PRIVATE' }))
                    dispatch(setNav({paginationPage: defaultCurrent, activeListingTab: activeTab, sorters, filters, query: fieldValue}))
                    navigate(el.routeSuffix + '/' + el.profileId + '/summary')
                }
                const filteredList = () => {
                    if(listingType === 'search') {
                        return clients.clientsSearchListing
                    } else {
                        return [...clients.clientsList].filter(el => 
                            listingType === 'leads' ? el.status === 'PENDING_KYC' :
                            listingType === 'active' ? el.status === 'VERIFIED' :
                            listingType === 'closed' ? el.status === undefined : false
                        )
                    }
                }
                return (
                    <TableCustom 
                        pagination={{ showSizeChanger: false, current: defaultCurrent }} 
                        onChange={onTableChange} 
                        locale={{ emptyText: <Empty description={listingType === 'search' && fieldValueResult !== '' ? `No results for query '${fieldValueResult}'` : `No data`}></Empty> }}
                        columns={columns(redirectToUser)}
                        dataSource={filteredList()
                            .map((profile, i) => {
                                const baseProfile = {
                                    name: profile.name,
                                    key: i,
                                    createdAt: profile.createdAt, 
                                    accountType: profile.accountType, 
                                    profileId: profile.profileId,
                                    id: profile.id, 
                                    routeSuffix
                                }
                                if (listingType === 'leads') {
                                    return { 
                                        ...baseProfile, 
                                        reviewStatus: profile.kycStatus,
                                    };
                                }
                                if (listingType === 'active') {
                                    return { 
                                        ...baseProfile, 
                                        activeProducts: profile.activeProducts, 
                                        businessRelation: profile.businessRelation
                                    };
                                }
                                if (listingType === 'closed') {
                                    return { 
                                        ...baseProfile, 
                                        activeProducts: profile.activeProducts, 
                                        reviewStatus: profile.kycStatus,
                                        businessRelation: profile.businessRelation
                                    };
                                }
                                if (listingType === 'search') {
                                    return { 
                                        ...baseProfile, 
                                        activeProducts: profile.activeProducts, 
                                        reviewStatus: profile.kycStatus,
                                        businessRelation: profile.businessRelation
                                    };
                                }
                    
                                return baseProfile
                            })
                        }
                    />
                )
            }}
        </TableElement>
    )
}

const ClientListing: FC<CProps> = ({listingType}) => {
    const clients = useSelector((state) => state.admin2.clients)
    const envs = useSelector((state) => state.general.envs)
    const {adminSuffix} = envs
    const sortersList = ['']
    const [fieldValue, setFieldValue] = useState('')
    const [fieldValueResult, setFieldValueResult] = useState('')
    const [initialLoad, setInitialLoad] = useState(false)
    const [querySetupDone, setQuerySetupDone] = useState(false)
    let navigate = useNavigate()
    let location = useLocation()
    const [activeTab, setActiveTab] = useState<ListingType>(location.pathname === adminSuffix ? 'leads' : location.pathname.includes('active') ? 'active' : location.pathname.includes('closed') ? 'closed' : location.pathname.includes('search') ? 'search' : 'leads')
    useEffect(() => {
        setQueryToState()
    }, [])
    useEffect(() => {
        setInitialLoad(false)
        setQuerySetupDone(true)
        if(activeTab !== 'search') {
            dispatch(clientsGet({params: {}}))
        }
    }, [activeTab])
    useEffect(() => {
        if(initialLoad === false && querySetupDone === true && activeTab === 'search') {
            updateRoute()
            dispatch(clientsGet({params: {query: fieldValue}}))
            setInitialLoad(true)
        }
    }, [querySetupDone, activeTab])
    const confirm = (link: ListingType) => {
        if(link !== activeTab) {
            dispatch(setNav({activeListingTab: link}))
            navigate(envs.routeSuffix + '/' + link)
            setActiveTab(link)
        }
    }
    const handleInputChange = (val: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(val.target.value)
    }
    const applyFilter = (e?: any) => {
        if(e) {
            e.preventDefault()
        }
        dispatch(clientsGet({params: {query: fieldValue}}))
        updateRoute()
        setFieldValueResult(fieldValue)
    }
    const clearFilter = () => {
        setFieldValue('')
        setFieldValueResult('')
        dispatch(clientsGetClear({}))
        updateRoute('')
    }
    const updateRoute = (val?: string) => {
        let paramsEl = new URLSearchParams(location.search)
        let queryValue = typeof val == "string" ? val : fieldValue
        paramsEl.set('query', queryValue)
        navigate(location.pathname + paramsEl ? '?' + paramsEl.toString() : '')
    }
    const setQueryToState = () => {
        let paramsEl = new URLSearchParams(location.search)
        let paramsQuery = paramsEl.get('query')
        if(paramsQuery) {
            setFieldValue(paramsQuery)
            setFieldValueResult(paramsQuery)
        }
        setQuerySetupDone(true)
    }
	return (
        <KycLayout listingView={true} pageName='Client listing' nav={{hideNav: true, step: -1}}>
            <NavCustomStyled>
                <div className='content'>
                    <Row justify='space-between'>
                        <Col>
                            <ButtonUpperTabs onClick={() => confirm('leads')}  className={activeTab === 'leads' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Leads</ButtonUpperTabs>
                            <ButtonUpperTabs onClick={() => confirm('active')} className={activeTab === 'active' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Active clients</ButtonUpperTabs>
                            <ButtonUpperTabs onClick={() => confirm('closed')} className={activeTab === 'closed' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Closed accounts</ButtonUpperTabs>
                            <ButtonUpperTabsSearch onClick={() => confirm('search')}  className={activeTab === 'search' ? 'btn-sm active' : 'btn-sm'} btnType='text'><form onSubmit={applyFilter}><FormInputStandalone label='search' name='search' placeholder='Search' onChange={handleInputChange} value={fieldValue} /><CloseOutlined onClick={clearFilter} /><SearchOutlined onClick={applyFilter} /></form></ButtonUpperTabsSearch>

                        </Col>
                    </Row>
                </div>
            </NavCustomStyled>
    		<PageContent style={{marginTop: 4, marginBottom: 10}}>
                {clients ? (
                    <>
                        {activeTab === 'leads' &&
                            <ClientsTable fieldValue={fieldValue} listingType='leads' sortersList={sortersList} activeTab={activeTab} />
                        }
                        {activeTab === 'active' &&
                            <ClientsTable fieldValue={fieldValue} listingType='active' sortersList={sortersList} activeTab={activeTab} />
                        }
                        {activeTab === 'closed' &&
                            <ClientsTable fieldValue={fieldValue} listingType='closed' sortersList={sortersList} activeTab={activeTab} />
                        }
                        {activeTab === 'search' &&
                            <ClientsTable fieldValue={fieldValue} listingType='search' sortersList={sortersList} activeTab={activeTab} fieldValueResult={fieldValueResult} />
                        }
                    </>
                ) : (<div></div>)}
                <br />
    		</PageContent>
        </KycLayout>
	)
}

export default ClientListing
