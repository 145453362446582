import * as Yup from 'yup'
import { FC, useEffect, useState } from 'react'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button, message } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { countries } from 'countries-list'
import { dispatch } from '../../../store/store'
import { authorizedPersonDocsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonDocsGet'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { signatoriesUpdate } from '../../../store/kyc-front/asyncThunks/signatoriesUpdate'
import { authorizedPersonsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonsGet'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { signatoriesUpdateClear } from '../../../store/kyc-front/slices/signatoriesSlice'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormSelect from '../ui-elements/FormSelect'
import KycNextSteps from '../KycNextSteps'
import { FormCheckboxRadio } from '../ui-elements/FormCheckboxRadio'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { ButtonUpper } from '../ui-elements/Buttons'
import KycDocsInfo from '../KycDocsInfo'
import { descriptions } from './common'

interface KProps {
    printRender?: boolean,
}


const Muted = styled.p`
    color: ${themeColor.gray}
`

const Signature = styled.div`
    border: 1px solid ${themeColor.grayLight};
    padding: 5px 10px;
    height: 100px;
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
`

const KycDataValidationForm: FC<KProps> = ({printRender}) => {
	const { Option } = Select;
    const company = useSelector((state) => state.user.company);
    const signatories = useSelector((state) => state.user.signatories);
    const {kycApplication} = useSelector((state) => state.user2.kycApplication)
    const envs = useSelector((state) => state.general.envs);
    const authorizedPersons = useSelector((state) => state.user.authorizedPersons);
    const initialValues = { 
        signatories: kycApplication.values.authorizedPersons?.filter((person: any) => person.isSignatory === 'true').map((person: any) => person.id).join(',')
    }
    const validation = Yup.object({
        signatories: Yup.string().required()
    });
    const signatoriesList = kycApplication.values.authorizedPersons?.filter((person: any) => person.isSignatory === 'true');
	return (
        <BasicFormWrapper id='dataValidationForm' initial={initialValues} validation={validation} dispatchSubmit={signatoriesUpdate} params={{companyId: envs.profileId}}  dispatchClear={signatoriesUpdateClear} dispatchGet={profileGet} successCondition={signatories.updatedSignatories?.Right?.value == true ? 'success' : ''} finalSubmit={true}>
            {({handleInputChange, onSelectChange, onDateChange, values, disabled}) => {
                const onSignatoryChange = (el: any) => {
                    onSelectChange(el, 'signatories');
                    dispatch(signatoriesUpdate({params: {companyId: envs.profileId}, data: {signatories: el.join()}}));
                }
                return (
                    <>
                        <div>
                            
                           
                            <p>1.  {descriptions.signatoriesDeclarations1}</p>

                            <p>2.  {descriptions.signatoriesDeclarations2}</p>

                            <p>3.  {descriptions.signatoriesDeclarations3_1} <b>{descriptions.signatoriesDeclarations3_2}</b> {descriptions.signatoriesDeclarations3_3}</p>

                            <Muted>{descriptions.signatoriesDeclarations4}</Muted>
                            
                            {printRender ? (
                                <>
                                    <Text>Signatures of authorized persons:</Text>
                                    <Row>
                                        {signatoriesList?.map((ap: any, i: number) => {
                                            return <Col><Signature key={'signature' + i}><span>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</span></Signature></Col>
                                        })}
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <KycNextSteps />
                                    <br />
                                    <Row gutter={30}>
                                        <Col md={24}>
                                            <FormSelect defaultValue={values.signatories !== '' ? values.signatories?.split(',') : values.signatories} mode="multiple" label='Who will be signing the documents? *' id='signatories' placeholder='Select at least one authorized person' onChange={(val) => onSignatoryChange(val)} disabled={disabled} type='company' >
                                                {authorizedPersons?.authorizedPersons.map((ap, i) => {
                                                    if(ap.roleType && ap.roleType === 'Director') {
                                                        return <Option key={'signatories' + i} value={ap.authorizedPersonId}>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</Option>
                                                    }
                                                    
                                                })}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Muted>(Signatory must be authorized to sign for the company)</Muted>
                                </>
                            )}
                        </div>
                    </>
                )
            }}
        </BasicFormWrapper>
    )
}

export default KycDataValidationForm
