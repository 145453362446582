import { FC, useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col, Switch, message } from 'antd'
import { useKycApprove } from '../../../hooks/useKycApprove'
import { useSelector } from '../../../hooks/useSelector'
import { themeColor } from '../../../styles/themeStyles'
import { dispatch } from '../../../store/store'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { kycApplicationApprovalsGet } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsGet'
import { kycApplicationApprovalsUpdate } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsUpdate'
import styled from 'styled-components'
import { kycApplicationApprovalsGetClear, kycApplicationApprovalsUpdateClear } from 'store/kyc-backend/slices/backKycApplication'
import { useLocation } from 'react-router-dom'

const Approve = styled(Col)`
    font-size: 13px;
    label {
        color: ${themeColor.gray};
    }
    .small {
        font-size: 12px;
        margin-left: 8px;
    }
    .ant-switch-checked {
        background-color: ${themeColor.green}
    }
    &.document-approve {
        position: relative;
        top: -37px;
        left: 80%;
        left: calc(100% - 120px);
        height: 0;
    }
`

interface IProps {
    onSwitchChange?: (value: any) => void,
    name: string,
    schemaParent?: string,
    schemaName?: string,
    type: 'ap' | 'ubo' | 'company' | 'individual' | 'document' | 'authorized-persons' | 'ubos',
    isDocument?: boolean
    personId?: string
}

const ApproveInput: FC<IProps> = ({
   onSwitchChange, name, schemaParent, schemaName, type, isDocument, personId
}) => {
    const envs = useSelector((state) => state.general.envs);
    const admin = useSelector((state) => state.admin.admin);
    const [isApproved, setIsApproved] = useState(false);
    const [approvesCount, setApprovesCount] = useState(0);
    const [approvesListing, setApprovesListing] = useState([]);
    const {kycApplication} = useSelector((state) => state.user2.kycApplication)
    const { disabledApprovalMsg, isApprovalDisabled } = useKycApprove()
    const {kycApplicationApprovals, approvalsUpdated, multiApprove} = useSelector((state) => state.admin2.applicationApprovals)
    let el = useLocation();
    let apUboID = el.pathname.split('/')[envs.admin ? 4 : 3];
    let isApOrUbo = type === 'authorized-persons' || type === 'ubos'
    const { isPending, isError, isFullfilled } = useActionStatus(kycApplicationApprovalsUpdate);
    const approvesGettingStatus = useActionStatus(kycApplicationApprovalsGet)
    const click = (el: any) => {
        //@ts-ignore
        let nameEl;
        
        if(schemaParent) {
            if(schemaName) {
                nameEl = {[schemaParent]: {[schemaName]: !isApproved}}
            } else {
                //@ts-ignore
                nameEl = {[schemaParent]: {[name]: !isApproved}}
            }
            
        } else {
            if(schemaName) {
                nameEl = {[schemaName]: !isApproved}
            }
            let splitEl =  name?.split('.');

            if(splitEl?.length > 1) {
                if(splitEl?.length === 2) {
                    if(isDocument) {
                        nameEl = {'documents': [{id: splitEl[1], kind: !isApproved}]}
                    } else {
                        nameEl = {[splitEl[0]]: {[splitEl[1]]: !isApproved}}
                    }
                }
                if(splitEl?.length === 3) {
                    nameEl = {[splitEl[0]]: {id: splitEl[1], [splitEl[2]]: !isApproved}}
                }
                
            } else {
                if(name === 'sourceOfWealthBusinessActivities') {
                    nameEl = {
                        sourceOfWealthBusinessActivities: !isApproved,
                        sourceOfWealthInheritance: !isApproved,
                        sourceOfWealthProfessionalOccupation: !isApproved,
                        sourceOfWealthOther: !isApproved
                    }
                } else {
                    if(name === 'isSignatory') {
                        const signatoriesList = kycApplication.values.authorizedPersons
                        nameEl = [];
                        signatoriesList?.forEach((el: any) => nameEl.push({id: el.id, isSignatory: !isApproved}))
                    } else {
                        //@ts-ignore
                        nameEl = {[name]: !isApproved}
                    }
                }
            }
            
        }
        if(name === 'isSignatory') {
            //@ts-ignore
            dispatch(kycApplicationApprovalsUpdate({params: {id: envs.profileId}, data: {authorizedPersons: nameEl}}))
        } else {
            if(isApOrUbo) {
                //@ts-ignore
                dispatch(kycApplicationApprovalsUpdate({params: {id: envs.profileId}, data: {[type === 'authorized-persons' ? 'authorizedPersons' : 'beneficialOwners']: [{id: apUboID, ...nameEl}]}}))
            } else {
                //@ts-ignore
                dispatch(kycApplicationApprovalsUpdate({params: {id: envs.profileId}, data: {...nameEl}}))
            }
        }
    }

    useEffect(() => {
        if(isError || isFullfilled) {
            //@ts-ignore
            if(!multiApprove && containsKey(approvalsUpdated, name)) {
                if(isError) {
                    message.error('Some error occurred while updating this element');
                }
                if(isFullfilled) {
                    message.success('This element was successfully updated');
                    setIsApproved(!isApproved);
                }
                dispatch(kycApplicationApprovalsUpdateClear({}))
                dispatch(kycApplicationApprovalsGet({params: {id: envs.profileId}}))
            }
        }
    }, [isError, isFullfilled]);
    
    useEffect(() => {
        if(approvesGettingStatus.isFullfilled || approvesGettingStatus.isError) {
            if(isApOrUbo) {
                if(type === 'authorized-persons') {
                    const personEl = kycApplicationApprovals.authorizedPersons.find((el: any) => el.id === apUboID)
                    if(personEl) {
                        if(isDocument) {
                            setApprovesListing(personEl.documents)
                        } else {
                            setApprovesListing(personEl.approvals)
                        }
                    }
                }
                if(type === 'ubos') {
                    const personEl = kycApplicationApprovals.beneficialOwners.find((el: any) => el.id === apUboID)
                    if(personEl) {
                        if(isDocument) {
                            setApprovesListing(personEl.documents)
                        } else {
                            setApprovesListing(personEl.approvals)
                        }
                    }
                }
            } else {
                if(isDocument) {
                    setApprovesListing(kycApplicationApprovals.documents);
                } else {
                    if(name === 'isSignatory') {
                        setApprovesListing(kycApplicationApprovals.authorizedPersons);
                    } else {
                        setApprovesListing(kycApplicationApprovals.approvals);
                    }
                }
            }
            
        }  
    }, [approvesGettingStatus])
    useEffect(() => {
        //@ts-ignore
        let thisApprove: any = isDocument ? approvesListing?.find((app: any) => app.id === name.split('.')[1])?.approvals[0] : approvesListing?.find((approve: any) => approve.slug === name && approve.ref === '' || approve.ref + '.' + approve.slug === name )
        if(thisApprove) {
            let count = thisApprove.approvedBy?.length;
            setApprovesCount(count);
            if(thisApprove.approvedBy?.find((el: string) => el === admin.profile.adminId)) {
                setIsApproved(true)
            } else {
                if(isApproved) {
                    setIsApproved(false);
                }
            }
            
        } else {
            if(name === 'isSignatory') {
                let count = 0;
                let adminAprovedAll = false;
                approvesListing?.forEach((p: any) => {
                    const approval = p.approvals.find((el: any) => el.slug === 'isSignatory');
                    if(approval?.approvedBy.length) {
                        count = approval.approvedBy.length
                    }
                    if(approval?.approvedBy?.find((el: string) => el === admin.profile.adminId)) {
                        adminAprovedAll = true
                    } else {
                        adminAprovedAll = false
                    }
                })
                setApprovesCount(count);
                setIsApproved(adminAprovedAll);
                
            } else {
                setApprovesCount(0);
                setIsApproved(false);
            }
        }
    }, [approvesListing])
    const containsKey = (obj: any, key: string) => {
        const keys = key.split('.');
        let found;
        if(isApOrUbo) {
            let personsListing = type === 'authorized-persons' ? obj.authorizedPersons : obj.beneficialOwners;
            let el = personsListing?.find((k: any) => k.id === apUboID)
            if(isDocument) {
                found = el?.documents?.find((k: any) => k.id === keys[1]) ? undefined : true
            } else {
                found = keys?.find(k => {
                    if (typeof el !== 'object' || el === null || !el.hasOwnProperty(k)) {
                    return true;
                    }
                    el = el[k];
                    return false;
                });
            }
        } else {
            if(isDocument) {
                found = obj?.documents?.find((k: any) => k.id === keys[1]) ? undefined : true
            } else {
                if(key === 'isSignatory') {
                    found = obj?.authorizedPersons?.find((k: any) => k.hasOwnProperty('isSignatory')) ? undefined : false
                }
                else {
                    found = keys?.find(k => {
                        if (typeof obj !== 'object' || obj === null || !obj.hasOwnProperty(k)) {
                        return true;
                        }
                        obj = obj[k];
                        return false;
                    });
                }
            }
        }
        return found === undefined;
    }
    return (
        <Approve className={isDocument ? 'document-approve' : ''}>
            {!isDocument && <label>Approved: </label>}
            <Switch onChange={click} size="small" checked={isApproved} disabled={isApprovalDisabled} title={disabledApprovalMsg}/>
            <label className='small'>{approvesCount} of 2 </label>
        </Approve>
	)
}

export default ApproveInput
