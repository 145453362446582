import Title from 'antd/es/typography/Title'
import * as Yup from 'yup'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, CheckOutlined, QuestionCircleOutlined, CaretLeftOutlined } from '@ant-design/icons'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { Row, Col, Divider, Space, Table, Badge, Popconfirm, message, Tooltip, Drawer, Form, Card } from 'antd'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { store, dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import { companyProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { riskProfileCriteriaGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCriteriaGet'
import { riskProfileCompanyGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyGet'
import { riskProfileIndividualGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualGet'
import { riskProfileCompanyEvaluationGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyEvaluationGet'
import { riskProfileIndividualEvaluationGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualEvaluationGet'
import { riskProfileCompanySnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotsGet'
import { riskProfileIndividualSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotsGet'
import { riskProfileCompanySnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotCreate'
import { riskProfileIndividualSnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotCreate'
import { riskProfileCompanySnapshotUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanySnapshotUpdate'
import { riskProfileIndividualSnapshotUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualSnapshotUpdate'
import { riskProfileCompanyApproveUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyApproveUpdate'
import { riskProfileIndividualApproveUpdate } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualApproveUpdate'
import { riskProfileCompanyDraftGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileCompanyDraftGet'
import { riskProfileIndividualDraftGet } from '../../../../store/kyc-backend/asyncThunks/riskProfileIndividualDraftGet'
import { 
	riskProfileCompanySnapshotsGetClear,
	riskProfileIndividualSnapshotsGetClear,
	riskProfileIndividualSnapshotCreateClear, 
	riskProfileCompanySnapshotCreateClear, 
	riskProfileIndividualSnapshotUpdateClear, 
	riskProfileCompanySnapshotUpdateClear, 
	riskProfileIndividualGetClear, 
	riskProfileCompanyGetClear,
	riskProfileIndividualEvaluationGetClear,
	riskProfileCompanyEvaluationGetClear,
	riskProfileCompanyApproveUpdateClear,
	riskProfileIndividualApproveUpdateClear,
	riskProfileCompanyDraftGetClear,
	riskProfileIndividualDraftGetClear
} from '../../../../store/kyc-backend/slices/riskSlice'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { FormInputStandalone } from '../../../kyc-front/ui-elements/FormInput'
import { FormCheckboxRadioFormStandalone } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import { SuccessInfo, SuccessIcon } from '../../../kyc-front/ui-elements/SuccessInfo'
import BasicFormWrapper from '../../../kyc-front/ui-elements/BasicForm'
import { DividerFirst } from '../../../kyc-front/ui-elements/Dividers'
import { camelCaseToName } from '../../../../utilities/nameParsers'
import { themeColor } from '../../../../styles/themeStyles'
import RiskProfileHistoryTable from './RiskProfileHistoryTable'
import RiskProfileForm from './RiskProfileForm'
import RiskProfilePrint from './RiskProfilePrint'
import { setFormUpdated } from '../../../../store/kyc-front/slices/navSlice'
import styled from 'styled-components'
import KycLayout from '../../../kyc-front/kyc-layout/KycLayout'

const CardCustom = styled(Card)`
	width: 300px;
	margin-right: 30px;
	text-align: center;
	p {
		font-size: 20px;
		font-style: italic;
		margin-bottom: 0; 
		lineHeight: 2
	}
	.ant-card-body {
		padding-top: 20px;
    	padding-bottom: 20px;
    	height: 100px;
	}
`

const CustomBadge = styled(Badge)`
	.ant-badge-count {
		font-weight: bold;
    	padding: 5px 10px;
    	height: auto;
    	font-size: 15px;
	}
	&.success .ant-badge-count {
		background-color: ${themeColor.green};
	}
	&.warning .ant-badge-count {
		background-color: orange;
	}
`

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`

const Summary = styled.p`
	span {
		font-size: 16px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
			margin-left: 5px;
		}
	}
`

const response = {
  "evaluationResults": [
    {
      "criterion": "string",
      "criteriaMet": 'false',
      "comments": [
        "string"
      ],
      "issues": [
      	"string"
      ],
      "criteriaMetOverride": 'false'
    }
  ],
  "totalScore": 0,
  "dueDiligenceScore": {},
  "calculatedAt": "string"
}

const TableCustom = styled(Table)`
	.criteriumMet {
		background-color: ${themeColor.grayOpaque}
	}
`

interface RProps {
    isArchived?: boolean
}

export type SnapStateType = {
	isApproved: boolean,
	isDraft: boolean,
	isLatestApproved: boolean,
	isEditable: boolean
}

type ApprovalsType = {
	clientAdvisorApprovedBy?: string,
	clientAdvisorApprovedAt?: string,
	complianceOfficeApprovedBy?: string,
	complianceOfficeApprovedAt?: string
}

type CreationType = {
	createdAt: string,
	dataVersion: string
}

interface SProps {
    totalScore: number,
    printRender: boolean
}

export const RiskStatus: FC<SProps> = ({totalScore, printRender}) => (
	<div className='currentScore' style={{marginBottom: 10}}>
		<Text style={printRender ? {display: 'inline-block', width: 120, fontWeight: 'bold'} : {fontSize: 25, fontWeight:'bold'}}>Current Score: </Text>
		<Text style={{fontSize: 25, fontWeight: 'bold'}} type={totalScore < 3 ? 'success' : 'warning'}> <CustomBadge className={totalScore < 3 ? 'success' : 'warning'} overflowCount={9999} count={totalScore} /><span style={{textTransform: 'uppercase', marginLeft: printRender ? 0 : 5, fontSize: 22}}>{printRender ? <span>{totalScore}</span> : <></>} {totalScore < 3 ? 'Normal risk' : 'Increased Risk'}</span></Text>
	</div>
)

const RiskProfile: FC<RProps> = ({isArchived}) => {
	const navigate = useNavigate();
	const envs = useSelector((state) => state.general.envs);
	const admin = useSelector((state) => state.admin.admin);
	const riskProfile = useSelector((state) => state.admin.riskProfile);
	const adminProfile = useSelector((state) => state.admin.admin);
	const profile = useSelector((state) => state.admin.backIndividual.individual);
	const companyProfile = useSelector((state) => state.admin.backCompany.profile);
	const isBusiness = envs.accountType === 'BUSINESS';
	const [dataLoaded, setDataLoaded] = useState(false);
	const [isKycData, setIsKycData] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const [initialData, setInitialData] = useState<any>({}); 
	const [initialDataLoaded, setInitialDataLoaded] = useState(false);
	const [createNew, setCreateNew] = useState(false);
	const [creatingNewStatus, setCreatingNewStatus] = useState(false);
	const [isBack, setBack] = useState(false);
	const [loadKycData, setLoadKycData] = useState(false);
	const [shouldReload, setShouldReload] = useState(false);
	const [shouldReloadListing, setShouldReloadListing] = useState(true);
	const [finalApprove, setFinalApprove] = useState(false);

	const [evaluationResults, setEvaluationResults] = useState([]);
	const [submittingComment, setSubmittingComment] = useState([]);
	const [approvals, setApprovals] = useState<ApprovalsType>({});
	const riskProfileSnapshotCreateStatus = useActionStatus(isBusiness ? riskProfileCompanySnapshotCreate : riskProfileIndividualSnapshotCreate);
	const riskProfileSnapshotUpdateStatus = useActionStatus(isBusiness ? riskProfileCompanySnapshotUpdate : riskProfileIndividualSnapshotUpdate);
	const pathParams = useParams();
	//@ts-ignore
	const [snapshotId, setSnapshotId] = useState(pathParams && pathParams[1] ? pathParams[1] : '');
	const [commentChanged, setCommentChanged] = useState(-1);
	const [creationAndVersion, setCreationAndVersion] = useState<CreationType>({
		dataVersion: '',
		createdAt: ''
	});
	const [snapStatus, setSnapStatus] = useState<SnapStateType>({
		isApproved: false,
		isDraft: false,
		isLatestApproved: false,
		isEditable: false
	});
	const initialDataKyc = isBusiness ? riskProfile?.riskProfileCompanyEvaluation : riskProfile?.riskProfileIndividualEvaluation;
	useEffect(() => {
		dispatch(setFormUpdated({updated: false, form: []}));
    }, []);
	useEffect(() => {
		if(dataLoaded === false && envs.accountType !== '') {
			if(isBusiness) {
	        	dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
	        	dispatch(riskProfileCompanyGet({ params: { companyId: envs.profileId} }));
	        }
	        if(envs.accountType === 'PRIVATE') {
	        	dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
	        }
	        dispatch(riskProfileCriteriaGet({params: null}));
	        setDataLoaded(true);
		}
        
    }, [envs.accountType]);
    const columns = [
	    {
	        title: 'Risk criteria',
	        dataIndex: 'criterion',
	        key: 'criterion',
	        render: (criterion: any) => <><span>{criterion.name === 'ContributorIsPEP' ? 'Contributor is PEP' : criterion.name === 'SpecificLicence2Operate' ? 'Has Specific License to Operate' : criterion.name === 'NoPersonalContact2Client' ? 'There Was No Personal Contact With Client' : camelCaseToName(criterion.name)}</span> <Tooltip title={criterion.description}><QuestionCircleOutlined style={{color: themeColor.gray}} /></Tooltip></>,
	    },
	    {
	        title: 'Result',
	        dataIndex: 'criteriaMet',
	        key: 'criteriaMet',
	        render: (criteriaMet: any) => <span>{criteriaMet.valueOverride !== null ? (criteriaMet.valueOverride === 'true' ? 'YES' : 'NO') : (criteriaMet.value === 'true' ? 'YES' : 'NO')} {criteriaMet.issues?.length ? <Tooltip title={criteriaMet.issues.join(', ')}><ExclamationCircleOutlined style={{color: themeColor.orangeWarning}} /></Tooltip>:<></>}</span>,
	    },
	    {
	        title: 'Available points',
	        dataIndex: 'points',
	        key: 'points',
	    },
	    {
	        title: 'Client score',
	        dataIndex: 'score',
	        key: 'score',
	        render: (score: number) => <span>{score < 0 ? <b style={{color: themeColor.green }}>{score}</b> : score !== 0 ? <b style={{color: themeColor.orangeWarning }}>{score}</b> : <>{score}</>}</span>,
	    },
	    Table.EXPAND_COLUMN
    ];
    const evaluationResultsMap = (data: any) => {
    	return data?.map((result: any, i: number) => ({
    		key: i,
       		criteriaMet: result.criteriaMet.toString(),
       		criteriaMetOverride: result.criteriaMetOverride !== undefined && result.criteriaMetOverride !== null ? result.criteriaMetOverride.toString() : null,
       		criterion: result.criterion,
       		isConclusive: result.isConclusive,
       		comments: result.comments,
       		issues: result.issues
       	}))
    }
    const initialValues = { 
       	evaluationResults: evaluationResultsMap(initialData?.evaluationResults),
		totalScore: initialData.totalScore,
		dueDiligenceScore: initialData.dueDiligenceScore,
		calculatedAt: initialData.calculatedAt
    }

    const initialKycValues = { 
    	//@ts-ignore
       	evaluationResults: evaluationResultsMap(initialDataKyc?.evaluationResults),
		totalScore: initialDataKyc.totalScore,
		dueDiligenceScore: initialDataKyc.dueDiligenceScore,
		calculatedAt: initialDataKyc.calculatedAt
    }
   
    useEffect(() => {
    	if(createNew) {
    		let resultsParsed = initialKycValues.evaluationResults?.map((result: any, i: number) => ({
	       		criteriaMet: result.criteriaMet === 'true' ? true : false,
	       		criteriaMetOverride: result.criteriaMetOverride !== undefined && result.criteriaMetOverride !== null ? result.criteriaMetOverride === 'true' ? true : false : null,
	       		criterion: result.criterion,
	       		isConclusive: result.isConclusive,
	       		comments: result.comments,
	       		issues: result.issues
	       	}));
    		if(isBusiness) {
				dispatch(riskProfileCompanySnapshotCreate({params: {companyId: envs.profileId}, data: resultsParsed})).then((el) => {
					//@ts-ignore
					navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile/' + el.payload.riskProfileSnapshot.snapshotId);
					handleHistoryShow(el.payload);
					setShouldReloadListing(true);
				})
			} else {
				dispatch(riskProfileIndividualSnapshotCreate({params: {individualId: envs.profileId}, data: resultsParsed})).then((el) => {
					//@ts-ignore
					navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile/' + el.payload.riskProfileSnapshot.snapshotId);
					handleHistoryShow(el.payload);
					setShouldReloadListing(true);
				})
			}
			setCreateNew(false);
			
    	}
    	
    }, [createNew]);
    useEffect(() => {
    	if(isBack && dataLoaded === false) {
    		callInitialFunctions();
    		setBack(false)
    	}
    	
    }, [dataLoaded]);
    useEffect(() => {
    	if(shouldReload) {
    		//callInitialFunctions();
    		setShouldReload(false);
    	}
    	
    }, [shouldReload]);
    useEffect(() => {
    	if(shouldReloadListing) {
	    	if(riskProfileSnapshotCreateStatus.isFullfilled && !riskProfileSnapshotCreateStatus.isCleared) {
	    		if(isBusiness) {
	    			dispatch(riskProfileCompanySnapshotsGet({params: {companyId: envs.profileId}}))
	    		} else {
	    			dispatch(riskProfileIndividualSnapshotsGet({params: {individualId: envs.profileId}}))
	    		}
	    		setShouldReloadListing(false);
	    	}
	    }
    }, [riskProfileSnapshotCreateStatus]);

    useEffect(() => {
    	if(shouldReloadListing) {
    		if(riskProfileSnapshotUpdateStatus.isFullfilled && !riskProfileSnapshotUpdateStatus.isCleared) {
	    		if(isBusiness) {
	    			dispatch(riskProfileCompanySnapshotsGet({params: {companyId: envs.profileId}}))
	    		} else {
	    			dispatch(riskProfileIndividualSnapshotsGet({params: {individualId: envs.profileId}}))
	    		}
	    		setShouldReloadListing(false);
	    		
	    	}
    	}
    	
    }, [riskProfileSnapshotUpdateStatus]);
    
    useEffect(() => {
    	if(loadKycData) {
    		setInitialDataLoaded(true);
			setInitialData(initialKycValues);
			//@ts-ignore
			setEvaluationResults(initialKycValues?.evaluationResults);
    		setShouldReload(true);
			setIsKycData(true);
			setLoadKycData(false);
    	}
	},[loadKycData])
    useEffect(() => {
    	return(() => {
    		dispatch(riskProfileCompanyGetClear({}));
    		dispatch(riskProfileIndividualGetClear({}));
    		dispatch(riskProfileCompanySnapshotsGetClear({}));
    		dispatch(riskProfileIndividualSnapshotsGetClear({}));
    		dispatch(riskProfileCompanyEvaluationGetClear({}));
    		dispatch(riskProfileIndividualEvaluationGetClear({}));
    		dispatch(riskProfileCompanySnapshotCreateClear({}));
    		dispatch(riskProfileIndividualSnapshotCreateClear({}));
    		dispatch(riskProfileIndividualApproveUpdateClear({}));
    		dispatch(riskProfileCompanyApproveUpdateClear({}));
    		dispatch(riskProfileCompanySnapshotUpdateClear({}))
    		dispatch(riskProfileIndividualSnapshotUpdateClear({}));
    	});
    }, []);
    useEffect(() => {
    	//@ts-ignore
		if(riskProfile.riskProfileIndividualSnapUpdated.error) {
			message.error('Some error occurred while updating this element');
		} else {
			if(riskProfile.riskProfileIndividualSnapUpdated.snapshotId !== "") {
				message.success('This element was successfully updated');
				dispatch(riskProfileIndividualSnapshotUpdateClear({}));
				setSubmittingComment([]);
				setCommentChanged(-1);
				dispatch(riskProfileIndividualDraftGet({params: {individualId: envs.profileId}})).then((resp) => {
	        		//@ts-ignore
	        		if(resp.payload !== "" && !resp.error) {
	        			setRiskProfileData(resp.payload);
	        			setInitialDataLoaded(true);
	        		}
	        	})
				
				
			}
		}
    }, [riskProfile.riskProfileIndividualSnapUpdated]);
    useEffect(() => {
		//@ts-ignore
		if(riskProfile.riskProfileCompanySnapUpdated.error) {
			message.error('Some error occurred while updating this element');
		} else {
			if(riskProfile.riskProfileCompanySnapUpdated.snapshotId !== "") {
				message.success('This element was successfully updated');
				dispatch(riskProfileCompanySnapshotUpdateClear({}));
				setSubmittingComment([]);
				setCommentChanged(-1);
				dispatch(riskProfileCompanyDraftGet({params: {companyId: envs.profileId}})).then((resp) => {
	        		//@ts-ignore
	        		if(resp.payload !== "" && !resp.error) {
	        			setRiskProfileData(resp.payload);
	        			setInitialDataLoaded(true);
	        		}
	        	})
				
				
			}
		}
    }, [riskProfile.riskProfileCompanySnapUpdated]);
    useEffect(() => {
    	//@ts-ignore
		if(riskProfile.riskProfileIndividualApproveUpdated.error) {
			message.error('Some error occurred while updating this element');
		} else {
			if(riskProfile.riskProfileIndividualApproveUpdated.snapshotId !== "") {
				message.success('This element was successfully updated');
				dispatch(riskProfileIndividualApproveUpdateClear({}));
				setSubmittingComment([]);
				setCommentChanged(-1);
				if(riskProfile.riskProfileIndividualApproveUpdated.clientAdvisorApprovedBy && riskProfile.riskProfileIndividualApproveUpdated.complianceOfficeApprovedBy) {
					setFinalApprove(true);
					dispatch(riskProfileIndividualGet({params: {individualId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setRiskProfileData(resp.payload);
		        			setInitialDataLoaded(true);
		        		}
		        	})
				} else {
					getDraftIndividual();
				}
				
			}
		}
    }, [riskProfile.riskProfileIndividualApproveUpdated]);
    useEffect(() => {
		//@ts-ignore
		if(riskProfile.riskProfileCompanyApproveUpdated.error) {
			message.error('Some error occurred while updating this element');
		} else {
			if(riskProfile.riskProfileCompanyApproveUpdated.snapshotId !== "") {
				message.success('This element was successfully updated');
				dispatch(riskProfileCompanyApproveUpdateClear({}));
				setSubmittingComment([]);
				setCommentChanged(-1);
				if(riskProfile.riskProfileCompanyApproveUpdated.clientAdvisorApprovedBy && riskProfile.riskProfileCompanyApproveUpdated.complianceOfficeApprovedBy) {
					setFinalApprove(true);
					dispatch(riskProfileCompanyGet({params: {companyId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setRiskProfileData(resp.payload);
		        			setInitialDataLoaded(true);
		        		}
		        	})
				} else {
					getCompanyDraft();
				}
				
			}
		}
    }, [riskProfile.riskProfileCompanyApproveUpdated]);

    useEffect(() => {
		if(riskProfile.newRiskProfileCompanySnap) {
			//@ts-ignore
			if(isKycData && riskProfile?.newRiskProfileCompanySnap?.riskProfileSnapshot?.snapshotId !== "") {
				message.success('This element was successfully created');
				setSubmittingComment([]);
				setCommentChanged(-1);
				//@ts-ignore
				setRiskProfileData(riskProfile.newRiskProfileCompanySnap.riskProfileSnapshot);
				dispatch(riskProfileCompanySnapshotCreateClear({}));
			}
		}
    }, [riskProfile.newRiskProfileCompanySnap]);

    useEffect(() => {
		if(riskProfile.newRiskProfileIndividualSnap) {
			//@ts-ignore
			if(isKycData && riskProfile?.newRiskProfileIndividualSnap?.riskProfileSnapshot?.snapshotId !== "") {
				message.success('This element was successfully created');
				setSubmittingComment([]);
				setCommentChanged(-1);
				//@ts-ignore
				setRiskProfileData(riskProfile.newRiskProfileIndividualSnap.riskProfileSnapshot);
				dispatch(riskProfileIndividualSnapshotCreateClear({}));
			}
		}
    }, [riskProfile.newRiskProfileIndividualSnap]);
    
	useEffect(() => {
		callInitialFunctions();
        
    }, [envs.accountType]);
    const setStatuses = (el?: any) => { 
    	if(el) {
    		setSnapStatus({
				isApproved: el.isApproved,
				isDraft: el.isDraft,
				isLatestApproved: el.isLatestApproved,
				isEditable: el.isEditable
			});
    	}
    	
    }
    const setRiskProfileData = (profileData: any) => {
    	setInitialData(JSON.parse(profileData?.data));
    	let resultEvaluation = evaluationResultsMap(JSON.parse(profileData.data).evaluationResults);
    	setCreationAndVersion({
    		createdAt: profileData.createdAt,
    		dataVersion: profileData.dataVersion?.RiskProfileV1 ? 'V1' : 'V1'
    	})
    	//setEvaluationResults(JSON.parse(profileData.data).evaluationResults);
    	setEvaluationResults(resultEvaluation);
    	setIsKycData(false);
    	setSnapshotId(profileData.snapshotId);
    	setStatuses(profileData);
    	//@ts-ignore
    	setApprovalsFunc(profileData);
    	setShouldReload(true);
    }
    const setApprovalsFunc = (resp: any) => {
    	setApprovals({
			clientAdvisorApprovedAt: resp?.clientAdvisorApprovedAt,
			clientAdvisorApprovedBy: resp?.clientAdvisorApprovedBy,
			complianceOfficeApprovedBy: resp?.complianceOfficeApprovedBy,
			complianceOfficeApprovedAt: resp?.complianceOfficeApprovedAt
		});
    }
    const handleHistoryShow = (historicalData: any) => {
		setInitialDataLoaded(false);
		setRiskProfileData(historicalData.riskProfileSnapshot ? historicalData.riskProfileSnapshot : historicalData);
		setVisible(false);
	}
	const handleBack = () => {
		setDataLoaded(false);
		setBack(true);
	}
    const handleConfirmVisibleChange = () => {
        setPopupVisible(!popupVisible);
    }
    const cancelConfirm = () => {
        setPopupVisible(false);
    }
    const confirmNew = () => {
    	setPopupVisible(false);
    	newProfileClicked();
    }
    const showDrawer = () => {
	    setVisible(true);
	}

	const onClose = () => {
	    setVisible(false);
	}
	const newProfileClicked = () => {
		setIsKycData(true);
		setCreateNew(true);
	}
	const callInitialFunctions = () => {
    	if(dataLoaded === false && envs.accountType !== '') {
			if(isArchived) {
				if(isBusiness) {
					dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
		        	dispatch(riskProfileCompanyEvaluationGet({params: {companyId: envs.profileId}}));
		        	dispatch(riskProfileCompanySnapshotsGet({ params: { companyId: envs.profileId} })).then((resp) => {
		        		//@ts-ignore
		        		let targetSnapshot = resp?.payload.find((elem: any) => elem.snapshotId === snapshotId);
		        		if(targetSnapshot) {
		        			setInitialDataLoaded(true);
		        			setRiskProfileData(targetSnapshot);
		        		} else {
		        			navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile');
		        		}
		        	})
				}
				if(envs.accountType === 'PRIVATE') {
					
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
		        	dispatch(riskProfileIndividualEvaluationGet({params: {individualId: envs.profileId}}));
		        	dispatch(riskProfileIndividualSnapshotsGet({ params: { individualId: envs.profileId} })).then((resp) => {
		        		//@ts-ignore
		        		let targetSnapshot = resp?.payload.find((elem: any) => elem.snapshotId === snapshotId);
		        		if(targetSnapshot) {
		        			setInitialDataLoaded(true);
		        			setRiskProfileData(targetSnapshot);
		        		} else {
		        			navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile');
		        		}
		        	})
				}
				setCommentChanged(-1);
		        setSubmittingComment([]);
				setDataLoaded(true);
			} else {
				if(isBusiness) {
		        	dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
		        	dispatch(riskProfileCompanyEvaluationGet({params: {companyId: envs.profileId}}));
		        	dispatch(riskProfileCompanyGet({params: {companyId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setInitialDataLoaded(true);
		        			setRiskProfileData(resp.payload);
		        		} else {
		        			getCompanyDraft();
		        		}
		        	})
		        }
		        if(envs.accountType === 'PRIVATE') {
		        	dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
		        	dispatch(riskProfileIndividualEvaluationGet({params: {individualId: envs.profileId}}));
		        	dispatch(riskProfileIndividualGet({params: {individualId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setInitialDataLoaded(true);
		        			setRiskProfileData(resp.payload);
		        		} else {
							getDraftIndividual();
		        		}
		        	})
		        }
		        setCommentChanged(-1);
		        setSubmittingComment([]);
		        setDataLoaded(true);
		    }
		}
    }
    const getDraftIndividual = () => {
    	dispatch(riskProfileIndividualDraftGet({params: {individualId: envs.profileId}})).then((respDraft) => {
			//@ts-ignore
			if(respDraft.payload !== "" && !respDraft.error) {
    			setInitialDataLoaded(true);
    			setRiskProfileData(respDraft.payload);
    		} else {
    			setLoadKycData(true);
    		}
		})
    }
    const getCompanyDraft = () => {
    	dispatch(riskProfileCompanyDraftGet({params: {companyId: envs.profileId}})).then((respDraft) => {
			//@ts-ignore
			if(respDraft.payload !== "" && !respDraft.error) {
    			setInitialDataLoaded(true);
    			setRiskProfileData(respDraft.payload);
    		} else {
    			setLoadKycData(true);
    		}
		})
    }
   	const handleSave = (i: number) => {
       	let resultsParsed = evaluationResults?.map((result: any, j: number) => ({
       		criteriaMet: result.criteriaMet === 'true' ? true : false,
       		criteriaMetOverride: result.criteriaMetOverride !== undefined && result.criteriaMetOverride !== null ? result.criteriaMetOverride === 'true' ? true : false : null,
       		criterion: result.criterion,
       		isConclusive: result.isConclusive,
       		comments: result.comments,
       		issues: result.issues
       	}));
       	let resultsParsedUpdate = evaluationResults?.map((result: any, k: number) => ({
       		criteriaMetOverride: result.criteriaMetOverride !== undefined && result.criteriaMetOverride !== null ? result.criteriaMetOverride === 'true' ? true : false : null,
       		criterionName: result.criterion.name,
       		comment: commentChanged === k ? result.comments[0] : undefined
       	}))
		if(!snapStatus.isDraft) {
			if(isBusiness) {
				//@ts-ignore
				dispatch(riskProfileCompanySnapshotCreate({params: {companyId: envs.profileId}, data: resultsParsed}))
			} else {
				//@ts-ignore
				dispatch(riskProfileIndividualSnapshotCreate({params: {individualId: envs.profileId}, data: resultsParsed}))
			}
		} else {
			if(isBusiness) {
				//@ts-ignore
				dispatch(riskProfileCompanySnapshotUpdate({params: {companyId: envs.profileId}, data: [resultsParsedUpdate[i]]}))
			} else { 
				//@ts-ignore
				dispatch(riskProfileIndividualSnapshotUpdate({params: {individualId: envs.profileId}, data: [resultsParsedUpdate[i]]}))
			}
		}
	}
	const handleInputChange = (e: any, i: number) => {
		const { name, value } = e.target;
		if(value !== '') {
			setCommentChanged(i);
			//@ts-ignore
			setSubmittingComment(prevComments => {prevComments[i] = value; return prevComments});
		} else {
			setCommentChanged(-1);
			//@ts-ignore
			setSubmittingComment(prevComments => { prevComments[i] = ''; return prevComments});
		}
		//@ts-ignore
		setEvaluationResults((prevState: any) => {
			prevState[i] = {
				...prevState[i],
		    	comments: [{
					comment: value,
					//@ts-ignore
					createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ss.00000000'),
					author: admin.profile.adminId
				}]
			}
			return ([
			    ...prevState
			])
		});
	}
	const handleRadioChange = (e: any, i: number) => {
		const { value } = e.target;
		//@ts-ignore
		setEvaluationResults((prevState: any) => {
			prevState[i] = {
				...prevState[i],
		    	criteriaMetOverride: value
			}
			return ([
			    ...prevState
			])
		})
	}
	const hideFinalApprove = () => {
    	setFinalApprove(false);
    	navigate(envs.routeSuffix + '/' + envs.profileId + '/risk-profile');

    }
	let disabled = snapStatus.isEditable ? false : isKycData ? false : true;
    let isEditable = snapStatus.isEditable ? true : isKycData ? true : false;
    let iterativeData = isKycData ? initialKycValues : initialData;
	return (
        (<KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'risk-profile'}}>
            {finalApprove ? (
				<div>
			            <Row justify='center'>
			                <CenteredCol>
			                    <SuccessIcon />
			                    <p><b>Approved!</b></p>
			                    <p>Your have succesfully approved this Risk Profile</p>
			                </CenteredCol>
			            </Row>
			            <Row justify='center' style={{'marginTop': '20px', 'marginBottom': '40px'}}>
			                <CenteredCol>
			                    <ButtonUpper onClick={hideFinalApprove} btnType='default'>View Risk Profile</ButtonUpper>
			                </CenteredCol>
			            </Row>
			    </div>
		    ) : (
				<Row gutter={30}>
					<Col span={24}>
						<Row justify='space-between'style={{'position': 'relative', 'top' : '-35px'}}>
							<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Risk Profile Status: 
								<Text style={{color: snapStatus.isLatestApproved ? themeColor.green : snapStatus.isEditable ? themeColor.blue : snapStatus.isApproved ? themeColor.greenGray : snapStatus.isDraft ? themeColor.blueGray : themeColor.gray}}><b> {snapStatus.isLatestApproved ? 'Approved' : snapStatus.isEditable ? 'Drafted' : snapStatus.isApproved ? 'Archived approved' : snapStatus.isDraft ? 'Archived draft' : 'Not initialized'}</b></Text>
							</Text>
							<div>
								{isArchived && <Link to={envs.routeSuffix + '/' + envs.profileId + '/risk-profile'} style={{marginRight: 10}}><ButtonUpper btnType='primary' ghost onClick={() => handleBack()}><CaretLeftOutlined />Back to latest</ButtonUpper></Link>}
								<span style={{marginRight: 10}}><ButtonUpper btnType='default' onClick={showDrawer}>History</ButtonUpper></span>
								<Popconfirm
			                        title="Are You sure you want to create new Risk Profile? This will override newest draft."
			                        visible={popupVisible}
			                        onVisibleChange={handleConfirmVisibleChange}
			                        onConfirm={confirmNew}
			                        onCancel={cancelConfirm}
			                        okText="Yes, create"
			                        cancelText="Cancel"
			                        icon={<QuestionCircleOutlined />}
			                    >
									<ButtonUpper btnType='primary' disabled={false}>New Profile</ButtonUpper>
								</Popconfirm>
							</div>
						</Row>
						<RiskStatus totalScore={initialData.totalScore} printRender={false} />
						<Row className='hide-print'>
							<Col span={24} style={{marginTop: -20, marginBottom: 20}}>
								<Divider />
								<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>Profile Summary</Title>
								<Summary>
									<span>Created at:</span>
									<span className='value'><b>{creationAndVersion.createdAt === '' ? 'Not created' : moment(creationAndVersion.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
								</Summary>
								{snapStatus.isApproved ? (
									<Summary>
										<span>Approved at:</span>
										<span className='value'><b>{moment(approvals.clientAdvisorApprovedAt).isAfter(approvals.complianceOfficeApprovedAt) ? moment(approvals.clientAdvisorApprovedAt).format('YYYY-MM-DD HH:mm:ss') : moment(approvals.complianceOfficeApprovedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
									</Summary>
									) : (<></>)
								}
								<Summary>
									<span>Version:</span>
									<span className='value'><b>{creationAndVersion.dataVersion === '' ? 'Not created' : creationAndVersion.dataVersion}</b></span>
								</Summary>
							</Col>
						</Row>
						<Divider style={{marginBottom: 0}} />
						<Drawer title="Risk Profile History" size='large' placement="right" onClose={onClose} open={visible}>
					        <RiskProfileHistoryTable handleHistoryShow={handleHistoryShow} />
					    </Drawer>
					    <RiskProfileForm 
					    	initialData={initialData} 
					    	isEditable={isEditable}
					    	handleSave={handleSave}
					    	iterativeData={iterativeData}
					    	snapStatus={snapStatus}
					    	approvals={approvals}
					    	snapshotId={snapshotId}
					    	evaluationResults={evaluationResults}
					    	submittingComment={submittingComment}
					    	handleRadioChange={handleRadioChange}
					    	handleInputChange={handleInputChange}
					    	printRender={false}
					    >
					    	<div style={{marginBottom: 30, display: snapStatus.isApproved ? 'block' : 'none'}}> 
				        		<Divider />
					        	<Title level={3}>Download Risk Profile</Title>
					        	<RiskProfilePrint approvals={approvals} totalScore={initialData.totalScore} name={isBusiness ? companyProfile.profile.name : profile.profile.firstName + ' ' + profile.profile.lastName} id={isBusiness ? companyProfile.companyId : profile.individualId}>
					        		<RiskProfileForm 
								    	initialData={initialData} 
								    	isEditable={isEditable}
								    	handleSave={handleSave}
								    	iterativeData={iterativeData}
								    	snapStatus={snapStatus}
								    	approvals={approvals}
								    	snapshotId={snapshotId}
								    	evaluationResults={evaluationResults}
								    	submittingComment={submittingComment}
								    	handleRadioChange={handleRadioChange}
								    	handleInputChange={handleInputChange}
								    	printRender={true}
								    />
					        	</RiskProfilePrint>
					        </div>
					    </RiskProfileForm>
					</Col>
				</Row>
			)}
        </KycLayout>)
    );
}

export default RiskProfile
