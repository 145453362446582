import { Comment } from '@ant-design/compatible';
import {
    Avatar,
    Button,
    Form,
    Input,
    List,
    Drawer,
    message,
    Badge,
    Typography,
    Row,
    Col,
    Tabs,
} from 'antd';
import Icon, { DoubleLeftOutlined } from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { moment } from '../ui-elements/FormDate'
import React, { useState, FC, useEffect, ReactNode } from 'react';
import { dispatch, AsyncThunkActionType, CaseReducerType } from '../../../store/store'
import { ButtonUpper } from '../ui-elements/Buttons'
import { MessagesListing } from '../ui-elements/Comment'
import Well from '../ui-elements/Well'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'
import { companyBackMessageViewedPut } from '../../../store/kyc-backend/asyncThunks/companyBackMessageViewedPut'
import { individualBackMessageViewedPut } from '../../../store/kyc-backend/asyncThunks/individualBackMessageViewedPut'
import { individualBackMessageViewedPutClear, companyBackMessageViewedPutClear, companyBackMessagesGetClear, individualBackMessagesGetClear } from '../../../store/kyc-backend/slices/messagesSlice'
import { companyBackMessagesGet } from '../../../store/kyc-backend/asyncThunks/companyBackMessagesGet'
import { companyNotesGet } from '../../../store/kyc-backend/asyncThunks/companyNotesGet'
import { companyBackMessagePost } from '../../../store/kyc-backend/asyncThunks/companyBackMessagePost'
import { companyNotePost } from '../../../store/kyc-backend/asyncThunks/companyNotePost'
import { companyNoteArchive } from '../../../store/kyc-backend/asyncThunks/companyNoteArchive'
import { companyBackMessagePostClear, individualBackMessagePostClear } from '../../../store/kyc-backend/slices/messagesSlice'
import { companyNoteArchiveClear, companyNotePostClear, individualNotePostClear, companyNotesGetClear, individualNotesGetClear } from '../../../store/kyc-backend/slices/notesSlice'
import { individualBackMessagesGet } from '../../../store/kyc-backend/asyncThunks/individualBackMessagesGet'
import { individualBackMessagePost } from '../../../store/kyc-backend/asyncThunks/individualBackMessagePost'
import { individualNotesGet } from '../../../store/kyc-backend/asyncThunks/individualNotesGet'
import { individualNotePost } from '../../../store/kyc-backend/asyncThunks/individualNotePost'
import { camelCaseToName, nameParse } from '../../../utilities/nameParsers'

const { TextArea } = Input;
const { Paragraph } = Typography;
const { TabPane } = Tabs;

const ButtonGroupItem = styled(ButtonUpper)`
    width: 100%;
`

const DrawerCustom = styled(Drawer)`
   .ant-drawer-content-wrapper {
        max-height: 100vh;
    }
`

const CustomBadge = styled(Badge)`
    z-index: 9;
    display: contents;
    .ant-badge-count {
        padding: 1px 6px;
        
    }
    .ant-btn-dashed {
        padding: 0;
        height: 33px;
        font-size: 14px;
    }
`

type MessageType = 'note' | 'message';
type SideType = 'client' | 'admin'


interface DrawerProps {
  type: MessageType
}

const FooterDrawer: FC<DrawerProps> = ({type}) => {
    const [visible, setVisible] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    const backCompany = useSelector((state) => state.admin.backCompany);
    const backIndividual = useSelector((state) => state.admin.backIndividual);
    const messages = useSelector((state) => state.admin.backMessages);
    const notes = useSelector((state) => state.admin.backNotes);
    const [dataLoaded, setDataLoaded] = useState(false);
    let isBusiness = envs.accountType === 'BUSINESS';
    let isNote = type === 'note';
    useEffect(() => {
        if(dataLoaded === false && envs.accountType !== '') {
            if(type === 'message') {
                if(envs.accountType === 'BUSINESS') {
                    dispatch(companyBackMessagesGet({ params: { companyId: envs.profileId } }))
                } else {
                    //@ts-ignore
                    dispatch(individualBackMessagesGet({ params: { individualId: envs.profileId } }))
                }
                
            }
            if(type === 'note') {
                if(envs.accountType === 'BUSINESS') {
                    dispatch(companyNotesGet({ params: { companyId: envs.profileId } }))
                } else {
                    dispatch(individualNotesGet({ params: { individualId: envs.profileId } }))
                }
            }
            setDataLoaded(true);
        }
    }, [envs.accountType]);
    
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  let nameEl = envs.accountType === 'BUSINESS' ? backCompany?.profile?.profile?.name : (backIndividual?.individual?.profile?.firstName ? backIndividual.individual.profile.firstName : 'New') + ' ' + (backIndividual?.individual?.profile?.lastName ? backIndividual.individual.profile.lastName : 'Person')
  return (
    <>
        {type === 'note' &&
            <ButtonGroupItem btnType="text" block onClick={showDrawer}>Notes</ButtonGroupItem>
        }
        {type === 'message' &&
            <CustomBadge count={envs.accountType === 'BUSINESS' ? messages.companyMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length : messages.individualMessages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length}>
                <ButtonGroupItem btnType="dashed" className='dashed-primary' block onClick={showDrawer}><span>Messages</span></ButtonGroupItem>
            </CustomBadge>
        }
        <DrawerCustom height={500} title={type === 'note' ? "Notes on KYC Client " + nameEl: "Messages to Client " + nameEl} placement="bottom" onClose={onClose} visible={visible}>
            <MessagesListing 
                type={type}
                side='admin'
                msgGet={isNote ? isBusiness ? companyNotesGet : individualNotesGet : isBusiness ? companyBackMessagesGet : individualBackMessagesGet} 
                msgGetClear={isNote ? isBusiness ? companyNotesGetClear : individualNotesGetClear : isBusiness ? companyBackMessagesGetClear : individualBackMessagesGetClear} 
                msgPostBusiness={isNote ? companyNotePost : companyBackMessagePost}
                msgPostPrivate={isNote ? individualNotePost : individualBackMessagePost}
                msgPostBusinessClear={isNote ? companyNotePostClear : companyBackMessagePostClear } 
                msgPostPrivateClear={isNote ? individualNotePostClear : individualBackMessagePostClear} 
                msgViewedPutBusiness={!isNote ? companyBackMessageViewedPut : undefined}
                msgViewedPutPrivate={!isNote ? individualBackMessageViewedPut : undefined}
                msgViewedPutBusinessClear={!isNote ? companyBackMessageViewedPutClear : undefined}
                msgViewedPutPrivateClear={!isNote ? individualBackMessageViewedPutClear : undefined}
                listing={isNote ? isBusiness ? notes.companyNotes : notes.individualNotes : isBusiness ? messages.companyMessages : messages.individualMessages}
                wasViewedBusiness={!isNote ? messages.companyMessageRead : undefined}
                wasViewedPrivate={!isNote ? messages.individualMessageRead : undefined}
                msgElemSentPrivate={isNote ? notes.individualNote : messages.individualMessageSent}
                msgElemSentBusiness={isNote ? notes.companyNote : messages.companyMessageSent}
            />
        </DrawerCustom>
    </>
  );
};


export default FooterDrawer;