import { ConfigProvider, Empty, Spin, Table, Tooltip } from 'antd'
import Text from 'antd/es/typography/Text'
import Title from 'antd/es/typography/Title'
import { ColumnType } from 'antd/lib/table'
import moment from 'moment'
import { FC, ReactNode, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import CompanyIcon from '../../../assets/corpo.svg'
import IndividualIcon from '../../../assets/individual.svg'
import { useSelector } from '../../../hooks/useSelector'
import { ADMIN_BASE_PATH } from '../../../routes/AppRoutes'
import {
	ClientActionPoint,
	clientActionPointsGetName,
	clientsActionPointsGet,
} from '../../../store/kyc-backend/asyncThunks/clientsActionPointsGet'
import { clientsGet } from '../../../store/kyc-backend/asyncThunks/clientsGet'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { dateFormatDetailed } from '../../kyc-front/ui-elements/FormDate'
import { PageContent } from '../../layout/PageContent'

const ClientName:FC<{ item?: ClientActionPoint }> = ({item}) => {
	const { clientsList } = useSelector(state => state.admin2.clients)
	const client = clientsList.find(({ id }) => id === item?.clientId)
	if (!client) {
		return <>-</>
	}
	const label = `${client?.name}`
	return <Link to={`${ADMIN_BASE_PATH}/${item?.clientId}`} style={{width: 250}}>
		<img src={client.accountType === 'COMPANY' ? CompanyIcon : IndividualIcon} alt={''} style={{height: '17px'}}/>&nbsp;&nbsp;
		<Text ellipsis={{ tooltip: label }} style={{ maxWidth: '220px' }}>{label}</Text>
	</Link>
}
const HeaderSpin: FC = () => {
	const { clientsActionPoints } = useSelector(state => state.admin2.clients)
	const actionPointsGetState = useSelector(state => state.general.responseInfo)[clientActionPointsGetName]
	
	return <Spin spinning={!!clientsActionPoints.length && actionPointsGetState?.status === 'pending'} size={'small'} style={{ marginLeft: '10px', lineHeight: 1 }}/>
}
const Reason:FC<{ item?: ClientActionPoint, timeAgo?: ReactNode }> = ({item, timeAgo}) => {
	const TimeBox:FC = ({children}) => <span style={{ borderBottom: `1px dashed ${themeColor.gray}`, color: themeColor.gray  }}>{children}</span>
	
	switch (item?.type){
		case 'kyc_abandoned': return <span>- <strong>abandoned KYC</strong> &nbsp;<TimeBox>{timeAgo} ago.</TimeBox></span>
		case 'kyc_submitted': return <span>- <strong>submitted KYC</strong> for approval &nbsp;<TimeBox>{timeAgo} ago.</TimeBox></span>
		case 'relation_inactive': return <span>- is waiting for <strong>activation</strong> for &nbsp;<TimeBox>{timeAgo}.</TimeBox></span>
		}
		return <span>- {item?.type} <u>{timeAgo}</u></span>
}
const GoToLink:FC<{ item?: ClientActionPoint }> = ({item}) => {
	const { apiURL, admin, adminSuffix, type, env, routeSuffix } = useSelector((state) => state.general.envs)
	const { clientsList } = useSelector(state => state.admin2.clients)
	const client = clientsList.find(({ id }) => id === item?.clientId)
	const navigate = useNavigate()
	
	const redirectToUser = (path?: 'relation') => {
		if (client?.profileId) {
			dispatch(setEnvs({
				apiURL,
				admin,
				adminSuffix,
				type,
				env,
				routeSuffix,
				profileId: client.profileId,
				accountType: client?.accountType === 'COMPANY' ? 'BUSINESS' : 'PRIVATE',
			}))
			navigate(`${ADMIN_BASE_PATH}/${client.profileId}/${path || ''}`)
		}
	}
	const LinkKyc: FC<{path?: 'relation'}> = ({ path, children }) => <a onClick={() => redirectToUser(path)} style={{ color: themeColor.blue }}>{children}</a>
	
	switch (item?.type) {
		case 'kyc_submitted':
			return <LinkKyc>review KYC</LinkKyc>
		case 'kyc_abandoned':
			return <LinkKyc>go to KYC summary</LinkKyc>
		case 'relation_inactive':
			return <LinkKyc path={'relation'}>go to business relation</LinkKyc>
	}
	return <LinkKyc>KYC summary</LinkKyc>
}
const timeAgo = (time?: string): ReactNode => {
	return <Tooltip title={moment(time).format(dateFormatDetailed)}>
		{moment(time).fromNow(true)}
	</Tooltip>
}

export const ClientsActionPoints: FC = () => {
	const { clientsActionPoints } = useSelector(state => state.admin2.clients)
	const actionPointsGetState = useSelector(state => state.general.responseInfo)[clientActionPointsGetName]
	
	useEffect(() => {
		dispatch(clientsActionPointsGet())
		dispatch(clientsGet())
	}, [])
	
	
	const columns: ColumnType<ClientActionPoint>[] = [
		{
			render: (v,r) => <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
					<ClientName item={r} />&nbsp;&nbsp;<Reason item={r} timeAgo={timeAgo(r.time)}/>
				</div>
				<GoToLink item={r} />
			</div>,
		}
	]
	
	return (
		<KycLayout listingView nav={{ hideNav: true, hideSteps: true, step: -1 }} adminView>
			<PageContent style={{marginTop: '-30px', marginBottom: 10}}>
				<HeaderSpin />
				<ConfigProvider renderEmpty={()=><Empty description={<Text style={{ fontSize: '1.2em' }}>no pending action points</Text>}/>}>
					<Table
						dataSource={clientsActionPoints}
						columns={columns}
						loading={!clientsActionPoints.length && actionPointsGetState?.status === 'pending'}
						pagination={{  showSizeChanger: false, pageSize: 20}}
						showHeader={false}
					/>
				</ConfigProvider>
			</PageContent>
		</KycLayout>
	)
}
